import React, { useEffect, useState } from "react";
import { Col, Button, Container } from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import axios from "axios";
import { DECLINED } from "../../utils/constants/apis";

export default () => {
  const history = useHistory();

  const handleRedirection = (path) => {
    history.push({ pathname: path });
  };

  const [Token, setToken] = useState("");

  useEffect(() => {
    const token = getTokenFromUrl();
    setToken(token);
    console.log("Token:", token);
    if (!token) {
      history.push(Routes.Presentation.path);
    } else {
    }
    // You can do further processing with the token here
  }, []);

  const getTokenFromUrl = () => {
    const queryString = window.location.hash;
    console.log(queryString);
    const tokenRegex = /[?&]token=([^&]+)/;
    const match = queryString.match(tokenRegex);
    return match ? match[1] : null;
  };

  const handleCancel = () => {
    // Handle cancel action here
    console.log("Cancelled");
  };

  const handleConfirm = () => {
    try {
      axios.post(DECLINED + "" + Token);
    } catch (error) {
      console.log(error);
    }
    history.push(Routes.SigninReviewer.path);
    console.log("Confirmed");
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
              <div className="text-center text-md-center mb-4 mt-md-0">
                <h6 className="mb-4">
                  Your response is: <strong>Disagree</strong>
                </h6>
                <p className="mb-4">
                  To confirm your response, please choose{" "}
                  <strong>“I confirm”</strong>
                </p>
                <p className="mb-0">
                  To change your response, please choose{" "}
                  <strong>“Cancel”</strong>
                </p>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <Button
                    variant="secondary"
                    className="w-100 me-3"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="success"
                    className="w-100"
                    onClick={handleConfirm}
                  >
                    I confirm
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Container>
      </section>
    </main>
  );
};
