import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faEye,
  faEyeSlash,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
  Alert,
} from "@themesberg/react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as authService from "../../services/auth.service";

import { Routes } from "../../routes";

export default () => {
  const location = window.location.href;
  let splitUrl = location.split("?");
  let splitToken = splitUrl[1].split("#");
  const searchParams = splitToken[0].split("=");
  const token = searchParams[1];
  console.log("token ====>", token);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [isError, setIsError] = useState(false);
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [isPasswordRepatShow, setIsPasswordRepatShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  const resetPassword = async (body) => {
    const { password } = body;
    const dataToSend = {
      password,
    };
    try {
      const result = await authService.resetPasswordService(dataToSend, token);
      if (result) {
        history.push({ pathname: Routes.Signin.path });
        setIsError(false);
        setErrorMessage("");
      }
    } catch (error) {
      // if (typeof error === "string") {
      const errorMessage = JSON.parse(error.message);
      if (Array.isArray(errorMessage.message)) {
        setErrorMessage(errorMessage.message[0]);
      } else {
        setErrorMessage(errorMessage.message);
      }
      // } else {
      //   setErrorMessage("Something Went Wrong!");
      // }
      setIsError(true);
    }
  };
  return (
    <main>
      <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          {isError && (
            <Alert
              variant={"danger"}
              style={{
                textAlign: "center",
              }}
            >
              Something Wrong! Your token is expired! please go back to forget
              password and and try again
            </Alert>
          )}
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link
                as={Link}
                to={Routes.Signin.path}
                className="text-gray-700"
              >
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to
                sign in
              </Card.Link>
            </p>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Reset password</h3>
                <Form>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Password*</Form.Label>
                    <InputGroup>
                      <Controller
                        control={control}
                        name="password"
                        defaultValue=""
                        rules={{
                          required: {
                            value: true,
                            message: "The password field is mandatory",
                          },
                        }}
                        render={({
                          field: { ref, ...field },
                          fieldState: { error },
                        }) => (
                          <Form.Control
                            autoFocus
                            required
                            type={isPasswordShow ? "text" : "password"}
                            placeholder="Password"
                            {...field}
                          />
                        )}
                      />
                      <InputGroup.Text>
                        <FontAwesomeIcon
                          onClick={() => setIsPasswordShow(!isPasswordShow)}
                          icon={isPasswordShow ? faEyeSlash : faEye}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                    <Form.Text>
                      * Password must be longer than or equal to 8 characters
                      and contain at least one uppercase letter, one lowercase
                      letter, one number, and one special character.
                    </Form.Text>
                    {errors && errors?.password && (
                      <Form.Text className="error-text">
                        {errors.password.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group id="confirmePassword" className="mb-4">
                    <Form.Label>Repeat Password*</Form.Label>
                    <InputGroup>
                      <Controller
                        control={control}
                        name="confirmePassword"
                        defaultValue=""
                        rules={{
                          required: {
                            value: true,
                            message: "The Repeat Password field is mandatory",
                          },
                          validate: (value) =>
                            value === watch("password") ||
                            "The confirmation password does not match the password",
                        }}
                        render={({
                          field: { ref, ...field },
                          fieldState: { error },
                        }) => (
                          <Form.Control
                            autoFocus
                            required
                            type={isPasswordRepatShow ? "text" : "password"}
                            placeholder="Repeat Password"
                            {...field}
                          />
                        )}
                      />
                      <InputGroup.Text>
                        <FontAwesomeIcon
                          onClick={() =>
                            setIsPasswordRepatShow(!isPasswordRepatShow)
                          }
                          icon={isPasswordRepatShow ? faEyeSlash : faEye}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                    {errors && errors?.confirmePassword && (
                      <Form.Text className="error-text">
                        {errors.confirmePassword.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    onClick={handleSubmit(resetPassword)}
                  >
                    Reset password
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
