import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
  Alert,
} from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import * as patterns from "../../utils/constants/patterns";
import * as authService from "../../services/auth.service";
import { Routes } from "../../routes";

export default () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSucces, setIsSucces] = useState(false);
  const history = useHistory();

  const verfiyAccount = async (body) => {
    try {
      console.log("body ====>", body);
      const result = await authService.verifyAccountService(
        body.verification_code
      );
      if (result) {
        history.push({ pathname: Routes.Signin.path });
        setIsError(false);
        setErrorMessage("");
        setIsSucces(true);
      }
    } catch (error) {
      // if (typeof error === "string") {
      const errorMessage = JSON.parse(error.message);
      if (Array.isArray(errorMessage.message)) {
        setErrorMessage(errorMessage.message[0]);
      } else {
        setErrorMessage(errorMessage.message);
      }
      // } else {
      //   setErrorMessage("Something Went Wrong!");
      // }
      setIsError(true);
    }
  };
  return (
    <main>
      {isError && (
        <Alert
          variant={"danger"}
          style={{
            textAlign: "center",
          }}
        >
          {errorMessage}!
        </Alert>
      )}
      {isSucces && (
        <Alert
          variant={"success"}
          style={{
            textAlign: "center",
          }}
        >
          Plesae check your email,If you haven't received the email, please
          check your spam folder as it might have been filtered there. If you
          still can't find it, please contact our support team for assistance.
        </Alert>
      )}
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link
                as={Link}
                to={Routes.Signin.path}
                className="text-gray-700"
              >
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to
                sign in
              </Card.Link>
            </p>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3>Verify Account</h3>
                <Form>
                  <div className="mb-4">
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>Code*</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>

                        <Controller
                          control={control}
                          name="verification_code"
                          defaultValue=""
                          rules={{
                            required: {
                              value: true,
                              message:
                                "The verification code  field is mandatory",
                            },
                          }}
                          render={({
                            field: { ref, ...field },
                            fieldState: { error },
                          }) => (
                            <Form.Control
                              autoFocus
                              required
                              type="number"
                              placeholder="717394"
                              {...field}
                            />
                          )}
                        />
                      </InputGroup>
                      {errors && errors?.verification_code && (
                        <Form.Text className="error-text">
                          {errors.verification_code.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </div>
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    onClick={handleSubmit(verfiyAccount)}
                  >
                    Verify Account
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
