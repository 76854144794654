import { makeAutoObservable } from "mobx";

class UserStore {
  userInfo = {};

  constructor() {
    makeAutoObservable(this);
  }
  // next page
  setUserInfo(value) {
    this.userInfo = value;
  }
}

export default new UserStore();
