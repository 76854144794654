import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faUser,
  faUnlockAlt,
  faBriefcase,
  faBuilding,
  faPhone,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Alert,
} from "@themesberg/react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";

import { Routes } from "../../routes";

import * as authService from "../../services/auth.service";
import * as patterns from "../../utils/constants/patterns";
import { Controller, useForm } from "react-hook-form";
import { countries } from "../../utils/constants";
import { useEffect } from "react";
import ChipInput from "material-ui-chip-input";

export default () => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const location = useLocation();
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [isPasswordRepatShow, setIsPasswordRepatShow] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSucces, setIsSucces] = useState(false);
  const [isAccountCreated, setIsAccountCreated] = useState(true);

  // Extracting the query parameter 'token' from the URL
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    setIsAccountCreated(false);
  }, []);

  const signUp = async (body) => {
    const { agreement, confirmePassword, ...updatedObj } = body;
    const dataToSend = {
      ...updatedObj,
      role: "author",
      keywords: body.keywords.join(","),
    };

    try {
      const result = await authService.signUpServiceReviewer(dataToSend, token);
      console.log("result  ===>", result);
      if (result) {
        history.push({ pathname: Routes.Signin.path });
        setIsError(false);
        setErrorMessage("");
        setIsSucces(true);
        setIsAccountCreated(true);
      }
    } catch (error) {
      console.log("error ====>", error);
      const errorMessage = JSON.parse(error.message);
      console.log("errorMessage ====>", errorMessage);

      if (Array.isArray(errorMessage.message)) {
        setErrorMessage(errorMessage.message[0]);
      } else {
        setErrorMessage(errorMessage.message);
      }
      setIsError(true);
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        {/* {!isAccountCreated ? (           */}
        <Container>
          {isError && (
            <Alert
              variant={"danger"}
              style={{
                textAlign: "center",
              }}
            >
              {errorMessage}!
            </Alert>
          )}
          {/* <p className="text-center">
              <Card.Link
                as={Link}
                to={Routes.Presentation.path}
                className="text-gray-700"
              >
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to
                homepage
              </Card.Link>
            </p> */}
          {isSucces && (
            <p className="text-center">
              <Card.Link as={Link} to={Routes.Signin.path} className="green">
                Please sign in to submit your paper
              </Card.Link>
            </p>
          )}
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign up as Reviewer</h3>
                </div>
                <Form
                  className="mt-4"
                  style={{ maxHeight: "88vh", overflowX: "auto" }}
                >
                  <h6 className="mb-4">Profile Information</h6>

                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Email*</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>

                      <Controller
                        control={control}
                        name="email"
                        defaultValue=""
                        rules={{
                          required: {
                            value: true,
                            message: "The email field is mandatory",
                          },
                          pattern: {
                            value: patterns.regExpEmail,
                            message:
                              "The email field should include '@' and '.'",
                          },
                        }}
                        render={({
                          field: { ref, ...field },
                          fieldState: { error },
                        }) => (
                          <Form.Control
                            autoFocus
                            required
                            type="email"
                            placeholder="example@company.com"
                            {...field}
                          />
                        )}
                      />
                    </InputGroup>
                    {errors && errors?.email && (
                      <Form.Text className="error-text">
                        {errors.email.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Password*</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Controller
                        control={control}
                        name="password"
                        defaultValue=""
                        rules={{
                          required: {
                            value: true,
                            message: "The password field is mandatory",
                          },
                        }}
                        render={({
                          field: { ref, ...field },
                          fieldState: { error },
                        }) => (
                          <Form.Control
                            autoFocus
                            required
                            type={isPasswordShow ? "text" : "password"}
                            placeholder="Password"
                            {...field}
                          />
                        )}
                      />
                      <InputGroup.Text>
                        <FontAwesomeIcon
                          onClick={() => setIsPasswordShow(!isPasswordShow)}
                          icon={isPasswordShow ? faEyeSlash : faEye}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                    <Form.Text>
                      * Password must be longer than or equal to 8 characters
                      and contain at least one uppercase letter, one lowercase
                      letter, one number, and one special character.
                    </Form.Text>
                    {errors && errors?.password && (
                      <Form.Text className="error-text">
                        {errors.password.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group id="confirmePassword" className="mb-4">
                    <Form.Label>Repeat Password*</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Controller
                        control={control}
                        name="confirmePassword"
                        defaultValue=""
                        rules={{
                          required: {
                            value: true,
                            message: "The Repeat Password field is mandatory",
                          },
                          validate: (value) =>
                            value === watch("password") ||
                            "The confirmation password does not match the password",
                        }}
                        render={({
                          field: { ref, ...field },
                          fieldState: { error },
                        }) => (
                          <Form.Control
                            autoFocus
                            required
                            type={isPasswordRepatShow ? "text" : "password"}
                            placeholder="Repeat Password"
                            {...field}
                          />
                        )}
                      />
                      <InputGroup.Text>
                        <FontAwesomeIcon
                          onClick={() =>
                            setIsPasswordRepatShow(!isPasswordRepatShow)
                          }
                          icon={isPasswordRepatShow ? faEyeSlash : faEye}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                    {errors && errors?.confirmePassword && (
                      <Form.Text className="error-text">
                        {errors.confirmePassword.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  {/* <h6 className="mb-4">Personal Information</h6> */}

                  {/* <Form.Group id="title" className="mb-4">
                    <Form.Label>Title*</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faBriefcase} />
                      </InputGroup.Text>
                      <Controller
                        control={control}
                        name="title"
                        defaultValue=""
                        rules={{
                          required: {
                            value: true,
                            message: "The Title field is mandatory",
                          },
                        }}
                        render={({
                          field: { ref, ...field },
                          fieldState: { error },
                        }) => (
                          <Form.Control
                            autoFocus
                            required
                            type="text"
                            placeholder="Title"
                            {...field}
                          />
                        )}
                      />
                    </InputGroup>
                    {errors && errors?.title && (
                      <Form.Text className="error-text">
                        {errors.title.message}
                      </Form.Text>
                    )}
                  </Form.Group> */}
                  {/* <Form.Group id="first_name" className="mb-4">
                    <Form.Label>Given Name*</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Controller
                        control={control}
                        name="first_name"
                        defaultValue=""
                        rules={{
                          required: {
                            value: true,
                            message: "The Given Name field is mandatory",
                          },
                        }}
                        render={({
                          field: { ref, ...field },
                          fieldState: { error },
                        }) => (
                          <Form.Control
                            autoFocus
                            required
                            type="text"
                            placeholder="Given Name"
                            {...field}
                          />
                        )}
                      />
                    </InputGroup>
                    {errors && errors?.first_name && (
                      <Form.Text className="error-text">
                        {errors.first_name.message}
                      </Form.Text>
                    )}
                  </Form.Group> */}
                  {/* <Form.Group id="familyName" className="mb-4">
                    <Form.Label>Family Name</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Controller
                        control={control}
                        name="last_name"
                        defaultValue=""
                        rules={{
                          required: {
                            value: true,
                            message: "The Family Name field is mandatory",
                          },
                        }}
                        render={({
                          field: { ref, ...field },
                          fieldState: { error },
                        }) => (
                          <Form.Control
                            autoFocus
                            required
                            type="text"
                            placeholder="Family Name"
                            {...field}
                          />
                        )}
                      />
                    </InputGroup>
                    {errors && errors?.last_name && (
                      <Form.Text className="error-text">
                        {errors.last_name.message}
                      </Form.Text>
                    )}
                  </Form.Group> */}
                  {/* <Form.Group controlId="country">
                    <Form.Label>Country</Form.Label>
                    <Controller
                      name="country"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: {
                          value: true,
                          message: "The Country field is mandatory",
                        },
                      }}
                      render={({ field }) => (
                        <Form.Control as="select" {...field}>
                          <option value="">Select a country</option>
                          {countries.map((country, index) => (
                            <option key={index} value={country}>
                              {country}
                            </option>
                          ))}
                        </Form.Control>
                      )}
                    />
                    {errors && errors?.country && (
                      <Form.Text className="error-text">
                        {errors.country.message}
                      </Form.Text>
                    )}
                  </Form.Group> */}
                  {/* <Form.Group id="degree" className="mb-4">
                    <Form.Label>Degree</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faBuilding} />
                      </InputGroup.Text>
                      <Controller
                        control={control}
                        name="degree"
                        defaultValue=""
                        render={({
                          field: { ref, ...field },
                          fieldState: { error },
                        }) => (
                          <Form.Control
                            autoFocus
                            required
                            type="text"
                            placeholder="Degree"
                            {...field}
                          />
                        )}
                      />
                    </InputGroup>
                  </Form.Group> */}
                  {/* <Form.Group id="phone" className="mb-4">
                    <Form.Label>Phone</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faPhone} />
                      </InputGroup.Text>
                      <Controller
                        control={control}
                        name="phone"
                        defaultValue=""
                        render={({
                          field: { ref, ...field },
                          fieldState: { error },
                        }) => (
                          <Form.Control
                            autoFocus
                            required
                            type="number"
                            placeholder="Phone"
                            {...field}
                          />
                        )}
                      />
                    </InputGroup>
                  </Form.Group> */}
                  <Form.Group id="keyowrds" className="mb-4">
                    <h6 style={{ color: "blue" }}>
                      Write Your Keyowrd And Press Entre
                    </h6>
                    <Form.Label>Keywords*</Form.Label>
                    <InputGroup>
                      <Controller
                        name="keywords"
                        control={control}
                        defaultValue={[]}
                        rules={{
                          required: {
                            value: true,
                            message: "The keywords field is mandatory",
                          },
                        }}
                        render={({ field }) => (
                          <ChipInput
                            variant="outlined"
                            value={field.value}
                            onAdd={(keyword) => {
                              field.onChange([...field.value, keyword]);
                            }}
                            onDelete={(keyword, index) => {
                              const newKeywords = [...field.value];
                              newKeywords.splice(index, 1);
                              field.onChange(newKeywords);
                            }}
                          />
                        )}
                      />
                    </InputGroup>
                    {errors && errors?.keywords && (
                      <Form.Text className="error-text">
                        {errors.keywords.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <FormCheck type="checkbox" className="mt-3 mb-3">
                    <Controller
                      control={control}
                      name="agreement"
                      defaultValue={false}
                      rules={{
                        required: "You must agree to the privacy statement",
                      }}
                      render={({ field }) => (
                        <FormCheck.Input
                          type="checkbox"
                          id="defaultCheck5"
                          className="me-2"
                          {...field}
                        />
                      )}
                    />
                    <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">
                      Yes, I agree with the journal's{" "}
                      <Card.Link
                        as={Link}
                        target="_blank"
                        to={Routes.PrivacyStatement.path}
                        className="underline-text"
                      >
                        Privacy Statement
                      </Card.Link>
                    </FormCheck.Label>
                  </FormCheck>

                  {errors && errors?.agreement && (
                    <Form.Text className="error-text">
                      {errors.agreement.message}
                    </Form.Text>
                  )}

                  <Button
                    variant="primary"
                    className="w-100"
                    onClick={handleSubmit(signUp)}
                  >
                    Register
                  </Button>
                </Form>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link
                      as={Link}
                      to={Routes.SigninReviewer.path}
                      className="fw-bold underline-text"
                    >
                      {` Login here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {/* ) : (
          <Container>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h6 className="mb-0">
                    Check your email for a message to confirm your registration.
                    Note that Editorial Manage may have assigned a different
                    Username if the one you selected is already in use.
                  </h6>
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <Button
                      variant="primary"
                      className="w-100"
                      onClick={() => handleRedirection(Routes.Signin.path)}
                    >
                      Go To Login Page
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Container>
        )} */}
      </section>
    </main>
  );
};
