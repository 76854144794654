import React from "react";
import { Col, Row, Card, Form, Button } from "@themesberg/react-bootstrap";
import { observer } from "mobx-react";
import { useGlobalContext } from "../../context/useGlobalContext";
import { Controller, useForm } from "react-hook-form";

const ArticleType = observer(() => {
  const { stepperStore, submissionStore } = useGlobalContext();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      articleType: submissionStore.articleType,
    },
  });

  const handleNextPage = (body) => {
    submissionStore.addArticleType(body.articleType);
    stepperStore.getNextPage();
  };
  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Article Type</h5>
        <Form>
          <Row className="align-items-center">
            <Col md={12} className="mb-3">
              <Form.Group id="article-type">
                <Form.Label>Select Article</Form.Label>
                <Controller
                  control={control}
                  name="articleType"
                  rules={{ required: "Please select an article type" }}
                  render={({ field }) => (
                    <Form.Select
                      {...field}
                      onChange={(e) => field.onChange(e.target.value)} // Update field value on change
                    >
                      <option value="" disabled>
                        Select Article Type
                      </option>
                      <option value="Original article">Original Article</option>
                      <option value="Review">Review</option>
                      <option value="Short communication">
                        Short Communication
                      </option>
                      <option value="Proceedings">Proceedings</option>
                      <option value="Perspective">Perspective</option>
                    </Form.Select>
                  )}
                />
                {errors && errors?.articleType && (
                  <Form.Text className="error-text">
                    {errors.articleType.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <div className="mt-3 position-btn-right">
            <Button variant="primary" onClick={handleSubmit(handleNextPage)}>
              Next
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
});

export default ArticleType;
