import React from "react";
import { Step, Stepper } from "react-form-stepper";
import ArticleType from "./StepperComponents/articleType";
import UploadFile from "./StepperComponents/uploadFile";
import { useState } from "react";
import SuggestReviewers from "./StepperComponents/suggestReviewers";
import AddAuthors from "./StepperComponents/addAuthors";
import Funding from "./StepperComponents/funding";
import SubmissionDeclaration from "./StepperComponents/submissionDeclaration";
import { useGlobalContext } from "../context/useGlobalContext";
import { observer } from "mobx-react";

const StepperComponent = observer(() => {
  const { stepperStore, submissionStore } = useGlobalContext();

  const redirectToSetp = (step) => {
    stepperStore.setActiveStep(step);
  };

  const handleNextArticle = () => {
    if (submissionStore.articleType !== "") {
      redirectToSetp(1);
    }
  };

  const handleNextFile = () => {
    if (submissionStore.fileLink !== "") {
      redirectToSetp(2);
    }
  };
  const handleNextReviwers = () => {
    if (submissionStore.reviewerList.length !== 0) {
      redirectToSetp(3);
    }
  };
  const handleNextAuthors = () => {
    if (submissionStore.authorList.length !== 0) {
      redirectToSetp(4);
    }
  };

  return (
    <div>
      <Stepper activeStep={stepperStore.activeStep}>
        <Step
          label="Article Type"
          onClick={() => redirectToSetp(0)}
          disabled={false}
        />
        <Step
          label="Upload your files"
          onClick={handleNextArticle}
          disabled={false}
        />
        <Step
          label="Suggest Reviewers"
          onClick={handleNextFile}
          disabled={false}
        />
        <Step
          label="Add Authors"
          onClick={handleNextReviwers}
          disabled={false}
        />
        <Step label="Funding" onClick={handleNextAuthors} disabled={false} />
        <Step
          label="Submission Declaration"
          onClick={() => redirectToSetp(5)}
          disabled={false}
        />
      </Stepper>
      <div>
        {stepperStore.activeStep === 0 && <ArticleType />}
        {stepperStore.activeStep === 1 && <UploadFile />}
        {stepperStore.activeStep === 2 && <SuggestReviewers />}
        {stepperStore.activeStep === 3 && <AddAuthors />}
        {stepperStore.activeStep === 4 && <Funding />}
        {stepperStore.activeStep === 5 && <SubmissionDeclaration />}
      </div>
    </div>
  );
});

export default StepperComponent;
