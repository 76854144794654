import React, { useEffect, useState } from 'react';
import {
  Card,
  Col, 
  Row,
  Container,
  Form,
  Button
} from "@themesberg/react-bootstrap";
import moment from 'moment-timezone';
import { BASE_URL } from '../../utils/constants/apis';
import { useHistory } from 'react-router-dom';

const LatestIssues = () => {
  const history = useHistory();

  const [filters, setFilters] = useState({
    issueNumber: '',
    coverStory: '',
    createdAt: ''
  });

  const [issuesList, setIssuesList] = useState([]);
  const [filteredIssues, setFilteredIssues] = useState([]);

  useEffect(() => {
    // Fetch all issues on component mount
    const fetchAllIssues = async () => {
      try {
        const response = await fetch(`https://api.baejournal.com/api/v1/issues`);
        const data = await response.json();
        setIssuesList(data);
        setFilteredIssues(data);
      } catch (error) {
        console.error('Error fetching issues:', error);
      }
    };

    fetchAllIssues();
  }, []);

  const handleCardClick = (id) => {
    history.push(`/issues/${id}`);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleSearch = async () => {
    try {
      const queryParams = new URLSearchParams();

      Object.keys(filters).forEach(key => {
        if (filters[key]) {
          queryParams.append(key, filters[key]);
        }
      });

      console.log('Search query:', queryParams.toString());
      const response = await fetch(`https://api.baejournal.com/api/v1/issues?${queryParams}`);
      const data = await response.json();
      console.log('Search results:', data);
      setFilteredIssues(data);
    } catch (error) {
      console.error('Error fetching filtered issues:', error);
    }
  };

  return (
    <Container className="ml-auto mr-auto" style={{ marginLeft: '260px' }}>
      <h2 className="text-2xl font-bold mt-6 mb-4">Latest Issues</h2>
      
      <Form className="mb-6">
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Issue Number</Form.Label>
              <Form.Control
                type="text"
                name="issueNumber"
                value={filters.issueNumber}
                onChange={handleInputChange}
                placeholder="Issue Number"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Cover Story</Form.Label>
              <Form.Control
                type="text"
                name="coverStory"
                value={filters.coverStory}
                onChange={handleInputChange}
                placeholder="Cover Story"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Creation Date</Form.Label>
              <Form.Control
                type="date"
                name="createdAt"
                value={filters.createdAt}
                onChange={handleInputChange}
                placeholder="Creation Date"
              />
            </Form.Group>
          </Col>
          <Col className="d-flex align-items-end">
            <Button variant="primary" onClick={handleSearch}>
              Search
            </Button>
          </Col>
        </Row>
      </Form>

      <Row className="d-flex justify-content-center">
        {filteredIssues.map((issue, index) => (
          <Col key={index} xs={12} md={6} lg={3} className="mb-4" style={{ width: "240px" }}>
            <Card onClick={() => handleCardClick(issue.id)} style={{ cursor: 'pointer' }}>
              <Card.Img variant="top" src={`${BASE_URL}/${issue.imageUrl}`} style={{ height: "255px" }} />
              <Card.Body className="d-flex flex-column align-items-center">
                <Card.Title>vol. {issue.volumeNumber}, Iss.{issue.issueNumber}</Card.Title>
                <Card.Text>{moment(issue.created_at).format('MMMM-D YYYY')}</Card.Text>
                <Card.Link href={issue.link}>Table of Contents</Card.Link>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default LatestIssues;
