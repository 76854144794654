import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faFolder, faChevronDown, faSignOutAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Image,
  Button,
  Container,
  Tooltip,
  OverlayTrigger,
  Navbar,
  Nav,
  Badge,
  NavDropdown,
} from "@themesberg/react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Routes } from "../routes";
import OJS_LOGO from "../assets/img/logo_ojs.png";
import { useGlobalContext } from "../context/useGlobalContext";
import SimpleBar from "simplebar-react";
import { CSSTransition } from "react-transition-group";
import Preloader from "../components/Preloader";
import LatestIssues from "./components/LatestIssues";
import * as issuesService from "../services/issues.service";

export default () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownSubmission, setShowDropdownSubmission] = useState(false);
  const { userStore } = useGlobalContext();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  const [isLoading, setIsLoading] = useState(false);
  const [issuesList, setIssuesList] = useState([]);
  const [volsList, setVolsList] = useState([]);
  const [show, setShow] = useState(false);

  const handleMouseEnter = () => {
    setShowDropdownSubmission(false);
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  const handleMouseEnterSubmission = () => {
    setShowDropdown(false);
    setShowDropdownSubmission(true);
  };

  const handleMouseLeaveSubmission = () => {
    setShowDropdownSubmission(false);
  };

  const getAllIssues = async () => {
    try {
      setIsLoading(true);
      const result = await issuesService.getIssues();
      setIsLoading(false);
      setIssuesList(result);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllVols = async () => {
    try {
      setIsLoading(true);
      const result = await issuesService.getVols();
      setIsLoading(false);
      setVolsList(result);
    } catch (error) {
      console.log(error);
    }
  };

  const getIssuesByVolume = async (volume) => {
    try {
      setIsLoading(true);
      const response = await fetch(`https://api.baejournal.com/api/v1/issues?volume=${volume}`);
      const data = await response.json();
      setIsLoading(false);
      setIssuesList(data);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllIssues();
    getAllVols();
  }, []);

  const onCollapse = () => setShow(!show);

  const NavItem = ({
    title,
    link,
    external,
    target,
    icon,
    image,
    badgeText,
    badgeBg = "secondary",
    badgeColor = "primary",
    onClick,
  }) => {
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={onClick}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  const redirectToPath = (path) => {
    history.push({ pathname: path });
  };

  return (
    <>
      <Preloader show={isLoading} />

      <Navbar
        variant="dark"
        expand="lg"
        bg="dark"
        className="navbar-transparent navbar-theme-primary sticky-top"
      >
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand
            as={HashLink}
            to="#home"
            className="me-lg-3 d-flex align-items-center"
          >
            <Image src={OJS_LOGO} className="logo-size" />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="ms-2 brand-text d-none d-md-inline">
                Biology Analysis
              </span>
              <span className="ms-2 brand-text d-none d-md-inline">
                And Evaluation
              </span>
            </div>
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            <Navbar.Collapse id="navbar-default-primary">
              <Nav className="navbar-nav-hover align-items-lg-center">
                <Nav.Link as={Link} to={Routes.Presentation.path}>
                  Home
                </Nav.Link>

                <NavDropdown
                  title={
                    <>
                      About The Journal <FontAwesomeIcon icon={faChevronDown} />
                    </>
                  }
                  id="pages-dropdown"
                  show={showDropdown}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <NavDropdown.Item
                    className="nav--sub-link-home"
                    as={HashLink}
                    to={Routes.Presentation.path}
                  >
                    Aim And Scopes
                  </NavDropdown.Item>
                  <NavDropdown.Item as={HashLink} to={Routes.Presentation.path}>
                    Call For Paper
                  </NavDropdown.Item>
                  <NavDropdown.Item as={HashLink} to={Routes.Presentation.path}>
                    Publisher
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={Routes.PrivacyStatement.path}>
                    Privacy Statement
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  as={Link}
                  to={Routes.EditorialBoard.path}
                  className="d-sm-none d-xl-inline"
                >
                  Editorial Board
                </Nav.Link>
                <NavDropdown
                  title={
                    <>
                      Submit Your Manuscript{" "}
                      <FontAwesomeIcon icon={faChevronDown} />
                    </>
                  }
                  id="pages-dropdown"
                  show={showDropdownSubmission}
                  onMouseEnter={handleMouseEnterSubmission}
                  onMouseLeave={handleMouseLeaveSubmission}
                >
                  {!localStorage.getItem("ojs_home_access_token") && (
                    <NavDropdown.Item as={Link} to={Routes.Signin.path}>
                      Online Submission
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item
                    as={Link}
                    to={Routes.PublicationEthics.path}
                  >
                    Publication ethics
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={Routes.GuidelineAuthor.path}>
                    Guideline for author
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={Routes.GuidelineReviewer.path}
                  >
                    Guideline for reviewer
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={Routes.GuidelineAssociateEditor.path}
                  >
                    Guideline for associate editor
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to={Routes.Issues.path}>
                  Issues
                </Nav.Link>
                <Nav.Link as={Link} to={Routes.Presentation.path}>
                  Contact
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            {localStorage.getItem("ojs_home_access_token") ? (
              userStore.userInfo && (
                <div
                  className="media d-flex align-items-center cursor-pointer"
                  onClick={() => redirectToPath(Routes.AllSubmissions.path)}
                >
                  <div className="media-body ms-2 text-white align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">
                      {userStore.userInfo.first_name}{" "}
                      {userStore.userInfo.last_name}
                    </span>
                  </div>
                </div>
              )
            ) : (
              <Button
                variant="outline-white"
                as={Link}
                to={Routes.Signin.path}
                className="ms-3"
              >
                Sign In
              </Button>
            )}
          </div>
        </Container>
      </Navbar>

      {issuesList && <LatestIssues issuesListData={issuesList} />}

      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${show ? "show" : ""} sidebar d-md-block bg-primary text-white pt-6`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center"></div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              {volsList?.map((elem, index) => (
                <NavItem
                  key={index}
                  title={`Vol. ${elem?.volumeNumber} (${elem?.volume})`}
                  onClick={() => getIssuesByVolume(elem?.volume)}
                />
              ))}
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>

      <footer
        className="footer py-6 bg-dark text-white position-relative"
        style={{ zIndex: 1000, marginTop: "300px" }}
      >
        <Container>
          <Row>
            <Col md={4}>
              <Navbar.Brand
                as={HashLink}
                to="#home"
                className="me-lg-3 mb-3 d-flex align-items-center"
              >
                <Image src={OJS_LOGO} />
                <span className="ms-2 brand-text">
                  Biology Analysis and Evaluation
                </span>
              </Navbar.Brand>
              <p>
                Is an international, peer-reviewed, open access journal of
                biological sciences.
              </p>
            </Col>
            <Col xs={6} md={4} className="mb-5 mb-lg-0">
              <span className="h5">Biol. Anal. Eval</span>
              <ul className="links-vertical mt-2">
                <li>
                  <Card.Link as={HashLink} to="#abbreviation">
                    Abbreviation
                  </Card.Link>
                </li>
                <li>
                  <Card.Link as={HashLink} to="#aimAndScope">
                    Aim And Scopes
                  </Card.Link>
                </li>
                <li>
                  <Card.Link as={HashLink} to="#callForPaper">
                    Call For Paper
                  </Card.Link>
                </li>
                <li>
                  <Card.Link as={HashLink} to="#publisher">
                    Publisher
                  </Card.Link>
                </li>
              </ul>
            </Col>
          </Row>
          <hr className="bg-gray my-5" />
          <Row>
            <Col className="mb-md-2">
              <div
                className="d-flex text-center justify-content-center align-items-center"
                role="contentinfo"
              >
                <p className="font-weight-normal font-small mb-0">
                  Copyright © Biology Analysis and Evaluation{" "}
                  <span className="current-year">2023</span>. All rights
                  reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
