import {
    defaultOptions,
    get
} from "../utils/functions/httpMethods";
import * as urlWebservice from "../utils/constants/apis";


export const getIssues = async () => {
    try {
        const result = await get(
            urlWebservice.GET_ALL_ISSUES,
            defaultOptions()
        );
        return result;
    } catch (error) {
        throw new Error(error);
    }
};

export const getVols = async () => {
    try {
        const result = await get(
            urlWebservice.GET_ALL_VOLS,
            defaultOptions()
        );
        return result;

    }catch(error) {
        throw new Error(error);
    }
}