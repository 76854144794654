import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Badge, Form, Button } from "@themesberg/react-bootstrap";
import Preloader from '../../components/Preloader';
import { BASE_URL } from '../../utils/constants/apis';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFilePdf } from '@fortawesome/free-solid-svg-icons';

const IssueDetail = () => {
    const { id } = useParams();
    const [issue, setIssue] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [filters, setFilters] = useState({
        title: '',
        articleType: '',
        articleCode: ''
    });
    const [filteredArticles, setFilteredArticles] = useState([]);
    console.log(filteredArticles, 'haoadabda')

    useEffect(() => {
        const fetchIssue = async () => {
            try {
                const response = await fetch(`https://api.baejournal.com/api/v1/issues/${id}`);
                const data = await response.json();
                setIssue(data);
                setFilteredArticles(data?.files || []);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching the issue:', error);
                setIsLoading(false);
            }
        };

        fetchIssue();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };

    const handleSearch = async () => {
        try {
            const queryParams = new URLSearchParams();

            Object.keys(filters).forEach(key => {
                if (filters[key]) {
                    queryParams.append(key, filters[key]);
                }
            });

            console.log('Search query:', queryParams.toString());
            const response = await fetch(`https://api.baejournal.com/api/v1/issues/${id}?${queryParams}`);
            const data = await response.json();
            console.log('Search results:', data);
            setFilteredArticles(data.files);
        } catch (error) {
            console.error('Error fetching filtered articles:', error);
        }
    };

    if (isLoading) {
        return <Preloader show={isLoading} />;
    }

    if (!issue) {
        return <div>Error loading issue details.</div>;
    }

    return (
        <Container style={{ marginLeft: '260px' }}>
            <h2 className="text-2xl font-bold my-6">
                Issues, Volume {issue.volumeNumber}, Issue {issue.issueNumber} ({moment(issue.created_at).format('MMMM-D YYYY')})
            </h2>
            <Row>
                <Col xs={12} md={3}>
                    <Card>
                        <Card.Img variant="top" src={`${BASE_URL}/${issue.imageUrl}`} />
                    </Card>
                </Col>
                <Col xs={12} md={9}>
                    <h5>Cover Story (<a href={`${BASE_URL}/${issue.imageUrl}`}>view full-size image</a>)</h5>
                    <p>{issue.coverStory}</p>
                    <br />
                    <ul>
                        <li>Issues are regarded as officially published after their release is announced to the {/* <a href={issue.mailingListLink}> table of contents alert mailing list</a>*/}.</li>
                        <li>You may {/*<a href={issue.emailAlertLink}> sign up for e-mail alerts</a>*/} to receive table of contents of newly released issues.</li>
                        <li>PDF is the official format for papers published in both, html and pdf forms. To view the papers in pdf format, click on the "PDF Full-text" link, and use the free {/*<a href={issue.adobeReaderLink}> Adobe Reader</a>*/} to open them.</li>
                    </ul>


                </Col>
            </Row>

            <Row className='mt-6 '>
                <Form className="d-flex justify-content-center mb-4">
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    value={filters.title}
                                    onChange={handleInputChange}
                                    placeholder="Title"
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Article Type</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="articleType"
                                    value={filters.articleType}
                                    onChange={handleInputChange}
                                    placeholder="Article Type"
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Article Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="articleCode"
                                    value={filters.articleCode}
                                    onChange={handleInputChange}
                                    placeholder="Article Code"
                                />
                            </Form.Group>
                        </Col>
                        <Col className="d-flex align-items-end">
                            <Button variant="primary" onClick={handleSearch}>
                                Search
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Row>
            {filteredArticles?.map((elem, index) => (

                <Row>
                    <Card className="mb-4">
                        <Card.Body>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <Badge bg="primary" className="me-2">Open Access</Badge>
                                        <Badge bg="danger">Article</Badge>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faFilePdf} className="ms-2" />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col key={index} xs={12}>
                                    <h5>{elem.title}</h5>
                                    {/* <p><i>By</i> {elem.funderName}</p> */}
                                    {/* <p><a href={elem.doi}>{elem.doi}</a></p> */}
                                    {/* <p>Viewed by {elem.views}</p> */}
                                    <p>Abstract: {elem.abstract}</p>
                                    {/* <p><a href={elem.sectionLink}>Read more</a></p>
                                    <p>(This article belongs to the Section <a href={elem.sectionLink}>{elem.section}</a>)</p> */}
                                    <p><a href={`${BASE_URL}/${elem?.filePath}/${elem?.mergedFile}`}>Full article</a></p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Row>
            ))}

        </Container>
    );
};

export default IssueDetail;
