import { defaultOptions, get } from "../utils/functions/httpMethods";
import * as urlWebservice from "../utils/constants/apis";

export const getUserInfo = async () => {
  try {
    const result = await get(urlWebservice.PROFILE_INFO, defaultOptions());
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
