import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  FormCheck,
} from "@themesberg/react-bootstrap";
import { observer } from "mobx-react";

import { useGlobalContext } from "../../context/useGlobalContext";
import * as patterns from "../../utils/constants/patterns";

import { Controller, useForm } from "react-hook-form";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddAuthors = observer(() => {
  const { stepperStore, submissionStore } = useGlobalContext();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [isErrorExist, setIsErrorExist] = useState(false);
  const [errorText, seterrorText] = useState("");

  const handleAddAuthor = (body) => {
    let authorForm = {
      first_name: body.first_name,
      last_name: body.last_name,
      institution: body.institution,
      email: body.email,
      degree: body.degree,
      contributorAuthorDegree: submissionStore.contributorAuthor,
      correspondingAuthor: submissionStore.correspondingAuthor,
    };

    const emailExists = submissionStore.authorList.some(
      (object) => object.email === authorForm.email
    );

    if (emailExists) {
      // Email already exists, return an error message
      setIsErrorExist(true);
      seterrorText("Email already exists in the list of authors");
      // You can handle the error as needed, e.g., return an error response to the user.
    } else {
      // Email doesn't exist, you can insert the new object into the array
      setIsErrorExist(false);
      seterrorText("");
      // Perform any other actions you need.
      submissionStore.setAuthorList(authorForm);
      submissionStore.setContributorAuthor(false);
      submissionStore.setCorrespondingAuthor(false);
      reset();
    }
  };

  useEffect(() => {
    console.log(
      "submissionStore.reviewerList ====>",
      submissionStore.reviewerList
    );
  }, [submissionStore.reviewerList]);

  const handleNextPage = () => {
    // if (submissionStore.authorList.length > 0) {
    //   setIsErrorExist(false);
    //   seterrorText("");
    stepperStore.getNextPage();
    // } else {
    //   setIsErrorExist(true);
    //   seterrorText("In minimum you need to add One Author");
    // }
  };
  const handlePreviousPage = () => {
    stepperStore.getPreviousPage();
  };

  const handleDeleteAuthor = (author) => {
    const index = submissionStore.authorList.indexOf(author);
    submissionStore.deleteAuthorFromList(index);
  };

  const handleOptionChange = (value) => {
    submissionStore.setCorrespondingAuthor(value);
  };

  const handleChangeContributorAuthor = (value) => {
    submissionStore.setContributorAuthor(value);
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Add Author</h5>
        <Form>
          {isErrorExist && (
            <Form.Text className="error-text">{errorText}</Form.Text>
          )}
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>First Name*</Form.Label>
                <Controller
                  control={control}
                  name="first_name"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "The First Name field is mandatory",
                    },
                  }}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Form.Control
                      required
                      type="text"
                      placeholder="First name"
                      {...field}
                    />
                  )}
                />
                {errors && errors?.first_name && (
                  <Form.Text className="error-text">
                    {errors.first_name.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Last Name*</Form.Label>
                <Controller
                  control={control}
                  name="last_name"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "The Last Name field is mandatory",
                    },
                  }}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Form.Control
                      required
                      type="text"
                      placeholder="Also last name"
                      {...field}
                    />
                  )}
                />
                {errors && errors?.last_name && (
                  <Form.Text className="error-text">
                    {errors.last_name.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Institution*</Form.Label>
                <Controller
                  control={control}
                  name="institution"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "The Institution field is mandatory",
                    },
                  }}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Form.Control
                      required
                      type="text"
                      placeholder="Institution"
                      {...field}
                    />
                  )}
                />
                {errors && errors?.institution && (
                  <Form.Text className="error-text">
                    {errors.institution.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="degree">
                <Form.Label>Email*</Form.Label>
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "The Email field is mandatory",
                    },
                    pattern: {
                      value: patterns.regExpEmail,
                      message: "The email field should include '@' and '.'",
                    },
                  }}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Form.Control
                      required
                      type="email"
                      placeholder="Email"
                      {...field}
                    />
                  )}
                />
                {errors && errors?.email && (
                  <Form.Text className="error-text">
                    {errors.email.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Degree*</Form.Label>
                <Controller
                  control={control}
                  name="degree"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "The Degree field is mandatory",
                    },
                  }}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Form.Control
                      required
                      type="text"
                      placeholder="Degree"
                      {...field}
                    />
                  )}
                />
                {errors && errors?.degree && (
                  <Form.Text className="error-text">
                    {errors.degree.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          {/* <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Country*</Form.Label>
                <Form.Group className="mb-2">
                  <Form.Label>Select Country</Form.Label>
                  <Form.Select id="state" defaultValue="0">
                    <option value="0">Country</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </Form.Select>
                </Form.Group>
              </Form.Group>
            </Col>
          </Row> */}
          <Row>
            {/* <Form.Check type="radio">
              <FormCheck.Input
                type="radio"
                id="defaultCheck5"
                className="me-2"
              />
              <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">
                Equal contributor author
              </FormCheck.Label>
            </Form.Check>
            <Form.Check type="radio" className="mt-1">
              <FormCheck.Input
                type="radio"
                id="defaultCheck5"
                className="me-2"
              />
              <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">
                This is a corresponding author
              </FormCheck.Label>
            </Form.Check> */}
            <Form.Check
              type="checkbox"
              id="defaultCheck5"
              className="me-2"
              checked={submissionStore.contributorAuthor === true}
              onChange={() =>
                handleChangeContributorAuthor(
                  !submissionStore.contributorAuthor
                )
              }
              label="Equal contributor author"
            />
            <Form.Check
              type="checkbox"
              id="defaultCheck6"
              checked={submissionStore.correspondingAuthor === true}
              onChange={() =>
                handleOptionChange(!submissionStore.correspondingAuthor)
              }
              label="This is a corresponding author"
              className="mb-0"
            />
          </Row>

          <div className="mt-3 position-btn-right">
            <Button variant="primary" onClick={handleSubmit(handleAddAuthor)}>
              Add Author
            </Button>
          </div>

          {submissionStore.authorList.length !== 0 && (
            <div>
              <h6>Authors List</h6>
              {submissionStore.authorList.map((author, index) => (
                <div key={index}>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <FontAwesomeIcon
                      onClick={() => handleDeleteAuthor(author)}
                      icon={faTimes}
                      style={{ cursor: "pointer" }}
                    />
                    <p></p>
                  </div>

                  <p>FirstName : {author.first_name}</p>
                  <p>LastName : {author.last_name}</p>
                  <p>Institution : {author.institution}</p>
                  <p>Email : {author.email}</p>
                  <p>Degree : {author.degree}</p>
                  <p>
                    Corresponding Author :{" "}
                    {author.correspondingAuthor ? "Yes" : "No"}
                  </p>
                </div>
              ))}
            </div>
          )}

          <div className="block-btns-stepper">
            <div className="mt-3">
              <Button variant="primary" onClick={handlePreviousPage}>
                Previous
              </Button>
            </div>
            <div className="mt-3">
              <Button variant="primary" onClick={handleNextPage}>
                Next
              </Button>
            </div>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
});

export default AddAuthors;
