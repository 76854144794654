// const BASE_URL = "http://localhost:8080";
export const BASE_URL = "https://api.baejournal.com";
const API_ROOT = `${BASE_URL}/api/v1`;

// Auth Webservices
export const LOGIN = `${API_ROOT}/authentication/signin`;
export const SIGNUP = `${API_ROOT}/authentication/signup`;
export const SIGNUP_RIVIEWER = `${API_ROOT}/files/create-reviwer`;
export const FORGET_PASSWORD = `${API_ROOT}/authentication/forgot-password`;
export const RESET_PASSWORD = `${API_ROOT}/authentication/reset-password`;
export const VERIY_ACCOUNT = `${API_ROOT}/authentication/verify-account`;

// User Webservices
export const PROFILE_INFO = `${API_ROOT}/users/profile`;

// Submision Webservices
export const UPLOAD_FILES = `${API_ROOT}/files/upload`;
export const EDIT_UPLOAD_FILES = `${API_ROOT}/files/upload`;
export const ADD_SUBMISSION = `${API_ROOT}/files/update-submission-declaration-and-article-type`;
export const GET_ALL_SUBMISSIONS = `${API_ROOT}/files/get-submissions`;
export const APPROVE_SUBMISSIONS = `${API_ROOT}/files/approve-submission`;
export const GET_SUBMISSION_BY_ID = `${API_ROOT}/files/get-submission`;
export const DELETE_SUBMISSION = `${API_ROOT}/files/delete-file`;
export const SEND_EMAIL = `${API_ROOT}/mail/send-build-pdf-mail`;
export const SUBMIT_REVIEW = `${API_ROOT}/files/submit-review`;
export const DECLINED = `${API_ROOT}/files/declined?token=`;


// issues
export const GET_ALL_ISSUES = `${API_ROOT}/issues`
export const GET_ALL_VOLS = `${API_ROOT}/issues/volume-issues`