import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faExternalLinkAlt,
  faTimesCircle,
  faCheckCircle,
  faCalendarAlt,
  faCodeBranch,
  faShoppingCart,
  faFolder,
  faMapMarkedAlt,
  faPager,
  faFileCode,
  faDownload,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  faBootstrap,
  faGithub,
  faJs,
  faReact,
  faSass,
} from "@fortawesome/free-brands-svg-icons";
import {
  Col,
  Row,
  Card,
  Image,
  Button,
  Container,
  ListGroup,
  Tooltip,
  OverlayTrigger,
  Form,
  Navbar,
  Nav,
  Badge,
  NavDropdown,
} from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Code from "../components/CodeEditor";
import GitHubButton from "react-github-btn";

import { Routes } from "../routes";
import ThemesbergLogoIcon from "../assets/img/themesberg.svg";
import ThemesbergLogo from "../assets/img/themesberg-logo.svg";
import MockupPresentation from "../assets/img/mockup-presentation.png";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import MapboxImg from "../assets/img/mockup-map-presentation.png";
import CalendarImg from "../assets/img/mockup-calendar-presentation.png";
import ReactMockupImg from "../assets/img/react-mockup.png";
import BS5IllustrationsImg from "../assets/img/illustrations/bs5-illustrations.svg";
import BS5Logo from "../assets/img/technologies/bootstrap-5-logo.svg";
import ReactLogo from "../assets/img/technologies/react-logo.svg";
import OJS_LOGO from "../assets/img/logo_ojs.png";

import pages from "../data/pages";
import features from "../data/features";
import { useGlobalContext } from "../context/useGlobalContext";

export default () => {
  const { userStore } = useGlobalContext();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownSubmission, setShowDropdownSubmission] = useState(false);
  const history = useHistory();

  const handleMouseEnter = () => {
    setShowDropdownSubmission(false);
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };
  const handleMouseEnterSubmission = () => {
    setShowDropdown(false);
    setShowDropdownSubmission(true);
  };

  const handleMouseLeaveSubmission = () => {
    setShowDropdownSubmission(false);
  };
  const redirectToPath = (path) => {
    history.push({ pathname: path });
  };

  const PagePreview = (props) => {
    const { name, image, link } = props;

    return (
      <Col xs={6} className="mb-5">
        <Card.Link
          as={Link}
          to={link}
          className="page-preview page-preview-lg scale-up-hover-2"
        >
          <Image
            src={image}
            className="shadow-lg rounded scale"
            alt="Dashboard page preview"
          />

          <div className="text-center show-on-hover">
            <h6 className="m-0 text-center text-white">
              {name}{" "}
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
            </h6>
          </div>
        </Card.Link>
      </Col>
    );
  };

  const Feature = (props) => {
    const { title, description, icon } = props;

    return (
      <Col xs={12} sm={6} lg={3}>
        <Card className="bg-white shadow-soft text-primary rounded mb-4">
          <div className="px-3 px-lg-4 py-5 text-center">
            <span className="icon icon-lg mb-4">
              <FontAwesomeIcon icon={icon} />
            </span>
            <h5 className="fw-bold text-primary">{title}</h5>
            <p>{description}</p>
          </div>
        </Card>
      </Col>
    );
  };

  const FolderItem = (props) => {
    const { name, icon, tooltip, iconColor } = props;
    const color = iconColor ? `text-${iconColor}` : "";

    return (
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="left"
        overlay={<Tooltip>{tooltip}</Tooltip>}
      >
        <li
          data-toggle="tooltip"
          data-placement="left"
          title="Main folder that you will be working with"
        >
          <FontAwesomeIcon
            icon={icon ? icon : faFolder}
            className={`${color} me-2`}
          />{" "}
          {name}
        </li>
      </OverlayTrigger>
    );
  };

  return (
    <>
      <Navbar
        variant="dark"
        expand="lg"
        bg="dark"
        className="navbar-transparent navbar-theme-primary sticky-top"
      >
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand
            as={HashLink}
            to="#home"
            className="me-lg-3 d-flex align-items-center"
          >
            <Image src={OJS_LOGO} className="logo-size" />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="ms-2 brand-text d-none d-md-inline">
                Biology Analysis
              </span>
              <span className="ms-2 brand-text d-none d-md-inline">
                And Evaluation
              </span>
            </div>
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            <Navbar.Collapse id="navbar-default-primary">
              <Nav className="navbar-nav-hover align-items-lg-center">
                <Nav.Link as={Link} to={Routes.Presentation.path}>
                  Home
                </Nav.Link>

                <NavDropdown
                  title={
                    <>
                      About The Journal <FontAwesomeIcon icon={faChevronDown} />
                    </>
                  }
                  id="pages-dropdown"
                  show={showDropdown}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <NavDropdown.Item as={Link} to={Routes.Presentation.path}>
                    Aim And Scopes
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={Routes.Presentation.path}>
                    Call For Paper
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={Routes.Presentation.path}>
                    Publisher
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={Routes.PrivacyStatement.path}>
                    Privacy Statement
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  as={Link}
                  to={Routes.EditorialBoard.path}
                  className="d-sm-none d-xl-inline"
                >
                  Editorial Board
                </Nav.Link>
                <NavDropdown
                  title={
                    <>
                      Submit Your Manuscript{" "}
                      <FontAwesomeIcon icon={faChevronDown} />
                    </>
                  }
                  id="pages-dropdown"
                  show={showDropdownSubmission}
                  onMouseEnter={handleMouseEnterSubmission}
                  onMouseLeave={handleMouseLeaveSubmission}
                >
                  {!localStorage.getItem("ojs_home_access_token") && (
                    <NavDropdown.Item as={Link} to={Routes.Signin.path}>
                      Online Submission
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item
                    as={Link}
                    to={Routes.PublicationEthics.path}
                  >
                    Publication ethics
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={Routes.Presentation.path}>
                    Guideline for author
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={Routes.Presentation.path}>
                    Guideline for reviewer
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={Routes.Presentation.path}>
                    Guideline for associate editor
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to={Routes.Issues.path}>
                  Issues
                </Nav.Link>
                <Nav.Link as={Link} to={Routes.Presentation.path}>
                  Contact
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            {localStorage.getItem("ojs_home_access_token") ? (
              userStore.userInfo && (
                <div
                  className="media d-flex align-items-center cursor-pointer"
                  onClick={() => redirectToPath(Routes.AllSubmissions.path)}
                >
                  {/* <Image
                    src={`https://www.gravatar.com/avatar/${userStore.userInfo.email}?d=identicon`}
                    className="user-avatar md-avatar rounded-circle"
                  /> */}
                  <div className="media-body ms-2 text-white align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">
                      {userStore.userInfo.first_name}{" "}
                      {userStore.userInfo.last_name}
                    </span>
                  </div>
                </div>
              )
            ) : (
              <>
                <Button
                  variant="outline-white"
                  as={Link}
                  to={Routes.Signin.path}
                  className="ms-3"
                >
                  Sign In
                </Button>
              </>
            )}
          </div>
        </Container>
      </Navbar>
      <section className="section-header overflow-hidden pt-5 pt-lg-6 pb-9 pb-lg-4 bg-primary text-white">
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <div className="react-big-icon d-none d-lg-block">
                <span className="fab fa-react"></span>
              </div>
              <h1 className="fw-bolder text-secondary">Privacy Statement</h1>
              <p className="text-muted fw-bold mb-5 h5">Date: 01 July 2023</p>
              <p className="text-muted fw-light mb-5 h6">
                Biology Analysis and Evaluation is committed to maintaining your
                confidence by respecting your privacy. This privacy statement
                explains how the journal collects and uses your data. It
                explains also how to secure your information.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section
        className="section section-md bg-soft pt-5 pt-lg-6"
        id="features"
      >
        <Container>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <h2 className="text-center">Data You Pro</h2>
            <Col lg={12} className="order-lg-2 mb-5 mb-lg-0">
              <p className="mb-3 lead fw-bold">
                Vide to Biology Analysis and Evaluation
              </p>
              <p className="mb-3 fw-bold">
                Biology Analysis and Evaluation is an international,
                peer-reviewed, open access journal of biological sciences.
                Biology Analysis and Evaluation aims in the advancement and the
                dissemination of knowledge concerning biological sciences. It
                accepts original research papers, reviewers, communications,
                conferences and perspectives on, but not limited to:
              </p>
              <ul>
                <li>Your full name, email address, degree, and phone number</li>
                <li>The login information such as username and password</li>
                <li>Files uploaded in your account</li>
                <li>Questions sent to the staff members</li>
              </ul>
            </Col>
            <Col lg={12} className="order-lg-2 mb-5 mb-lg-0">
              <p className="mb-3 lead fw-bold">
                How Biology Analysis and Evaluation use your data
              </p>
              <p className="mb-3 fw-bold">
                Biology Analysis and Evaluation exclusively uses the data that
                you provide to:
              </p>
              <ul>
                <li>Handle your access to and the exploit of the service</li>
                <li>
                  Invite you to participate in a future publishing program
                </li>
                <li>Invite you to review manuscripts</li>
                <li>
                  Inform you about the updated states of your submitted
                  manuscript
                </li>
                <li>Give you a continuous information about the journal</li>
              </ul>
            </Col>
            <Col lg={12} className="order-lg-2 mb-5 mb-lg-0">
              <p className="mb-3 lead fw-bold">Data security</p>
              <p className="mb-3 fw-bold">
                Biology Analysis and Evaluation takes all measures to safeguard
                your personal information against misuse, theft, destruction,
                alteration and loss. Precautions are also taken against
                unauthorized access and disclosure. These measures and
                precautions are taken through technical tools.
              </p>
            </Col>
            <Col lg={12} className="order-lg-2 mb-5 mb-lg-0">
              <p className="mb-3 lead fw-bold">Changes</p>
              <p className="mb-3 fw-bold">
                Biology Analysis and Evaluation will update this privacy
                statement from time to time. Changes and updated revision date
                will be posted on this page.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <footer className="footer py-6 bg-dark text-white">
        <Container>
          <Row>
            <Col md={4}>
              <Navbar.Brand
                as={HashLink}
                to="#home"
                className="me-lg-3 mb-3 d-flex align-items-center"
              >
                <Image src={OJS_LOGO} />
                <span className="ms-2 brand-text">
                  Biology Analysis and Evaluation
                </span>
              </Navbar.Brand>
              <p>
                Is an international, peer-reviewed, open access journal of
                biological sciences.
              </p>
            </Col>
            <Col xs={6} md={4} className="mb-5 mb-lg-0">
              <span className="h5">Biol. Anal. Eval</span>
              <ul className="links-vertical mt-2">
                <li>
                  <Card.Link as={Link} to={Routes.Presentation.path}>
                    Abbreviation
                  </Card.Link>
                </li>
                <li>
                  <Card.Link as={Link} to={Routes.Presentation.path}>
                    Aim And Scopes
                  </Card.Link>
                </li>
                <li>
                  <Card.Link as={Link} to={Routes.Presentation.path}>
                    Call For Paper
                  </Card.Link>
                </li>
                <li>
                  <Card.Link as={Link} to={Routes.Presentation.path}>
                    Publisher
                  </Card.Link>
                </li>
              </ul>
            </Col>
            {/* <Col xs={12} md={4} className="mb-5 mb-lg-0">
              <span className="h5 mb-3 d-block">Contact Us</span>
              <form action="#">
                <div className="form-row mb-2">
                  <div className="col-12">
                    <input
                      type="email"
                      className="form-control mb-2"
                      placeholder="example@company.com"
                      name="email"
                      aria-label="Subscribe form"
                      required
                    />
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Object"
                      name="object"
                      aria-label="Subscribe form"
                      required
                    />
                    <textarea
                      type="text"
                      className="form-control mb-2"
                      placeholder="Message"
                      name="message"
                      aria-label="Subscribe form"
                      required
                    />
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-secondary text-dark shadow-soft btn-block"
                      data-loading-text="Sending"
                    >
                      <span>Contact</span>
                    </button>
                  </div>
                </div>
              </form>
            </Col> */}
          </Row>
          <hr className="bg-gray my-5" />
          <Row>
            <Col className="mb-md-2">
              <div
                className="d-flex text-center justify-content-center align-items-center"
                role="contentinfo"
              >
                <p className="font-weight-normal font-small mb-0">
                  Copyright © Biology Analysis and Evaluation{" "}
                  <span className="current-year">2023</span>. All rights
                  reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
