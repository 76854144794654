import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
  Alert,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import * as patterns from "../../utils/constants/patterns";
import * as authService from "../../services/auth.service";
import { Routes } from "../../routes";

export default () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSucces, setIsSucces] = useState(false);

  const forgetPassword = async (body) => {
    try {
      const result = await authService.forgetPasswordService(body);
      if (result) {
        // history.push({ pathname: Routes.Signin.path });
        setIsError(false);
        setErrorMessage("");
        setIsSucces(true);
      }
    } catch (error) {
      // if (typeof error === "string") {
      const errorMessage = JSON.parse(error.message);
      if (Array.isArray(errorMessage.message)) {
        setErrorMessage(errorMessage.message[0]);
      } else {
        setErrorMessage(errorMessage.message);
      }
      // } else {
      //   setErrorMessage("Something Went Wrong!");
      // }
      setIsError(true);
    }
  };
  return (
    <main>
      {isError && (
        <Alert
          variant={"danger"}
          style={{
            textAlign: "center",
          }}
        >
          {errorMessage}!
        </Alert>
      )}
      {isSucces && (
        <Alert
          variant={"success"}
          style={{
            textAlign: "center",
          }}
        >
          Please check your email. For any help you can contact
          support@baejournal.com
        </Alert>
      )}
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link
                as={Link}
                to={Routes.Signin.path}
                className="text-gray-700"
              >
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to
                sign in
              </Card.Link>
            </p>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3>Forgot your password?</h3>
                <p className="mb-4">
                  Don't fret! Just type in your email and we will send you a
                  code to reset your password!
                </p>
                <Form>
                  <div className="mb-4">
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>Email*</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>

                        <Controller
                          control={control}
                          name="email"
                          defaultValue=""
                          rules={{
                            required: {
                              value: true,
                              message: "The email field is mandatory",
                            },
                            pattern: {
                              value: patterns.regExpEmail,
                              message:
                                "The email field should include '@' and '.'",
                            },
                          }}
                          render={({
                            field: { ref, ...field },
                            fieldState: { error },
                          }) => (
                            <Form.Control
                              autoFocus
                              required
                              type="email"
                              placeholder="example@company.com"
                              {...field}
                            />
                          )}
                        />
                      </InputGroup>
                      {errors && errors?.email && (
                        <Form.Text className="error-text">
                          {errors.email.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </div>
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    onClick={handleSubmit(forgetPassword)}
                  >
                    Recover password
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
