import {
  patch,
  post,
  put,
  resetPasswordOptions,
} from "../utils/functions/httpMethods";
import * as urlWebservice from "../utils/constants/apis";

export const loginService = async (payload) => {
  try {
    const result = await post(urlWebservice.LOGIN, payload);
    return result;
  } catch (error) {
    if (error && error.response) {
      throw new Error(JSON.stringify(error.response.data));
    } else {
      throw new Error(error.message);
    }
  }
};
export const signUpService = async (payload) => {
  try {
    const result = await post(urlWebservice.SIGNUP, payload);
    return result;
  } catch (error) {
    if (error.response) {
      throw new Error(JSON.stringify(error.response.data));
    } else {
      throw new Error(error.message);
    }
  }
};
export const signUpServiceReviewer = async (payload, token) => {
  try {
    const result = await post(
      `${urlWebservice.SIGNUP_RIVIEWER}?token=${token}`,
      payload
    );
    return result;
  } catch (error) {
    if (error.response) {
      throw new Error(JSON.stringify(error.response.data));
    } else {
      throw new Error(error.message);
    }
  }
};

export const forgetPasswordService = async (payload) => {
  try {
    const result = await post(urlWebservice.FORGET_PASSWORD, payload);
    return result;
  } catch (error) {
    if (error.response) {
      throw new Error(JSON.stringify(error.response.data));
    } else {
      throw new Error(error.message);
    }
  }
};

export const resetPasswordService = async (payload, token) => {
  try {
    const result = await put(
      urlWebservice.RESET_PASSWORD,
      payload,
      resetPasswordOptions(token)
    );
    return result;
  } catch (error) {
    if (error.response) {
      throw new Error(JSON.stringify(error.response.data));
    } else {
      throw new Error(error.message);
    }
  }
};

export const verifyAccountService = async (verificationCode) => {
  try {
    const result = await patch(
      `${urlWebservice.VERIY_ACCOUNT}/${verificationCode}`
    );
    return result;
  } catch (error) {
    if (error.response) {
      throw new Error(JSON.stringify(error.response.data));
    } else {
      throw new Error(error.message);
    }
  }
};
