import React, { useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { observer } from "mobx-react";

import { useGlobalContext } from "../../context/useGlobalContext";
import * as patterns from "../../utils/constants/patterns";

import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";

const SuggestReviewers = observer(() => {
  const { stepperStore, submissionStore } = useGlobalContext();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [isErrorExist, setIsErrorExist] = useState(false);
  const [errorText, seterrorText] = useState(
    "You have to add at least 3 reviewers"
  );

  const handleNextPage = () => {
    if (
      submissionStore.reviewerList.length > 2 &&
      submissionStore.reviewerList.length < 6
    ) {
      setIsErrorExist(false);
      seterrorText("");
      stepperStore.getNextPage();
    } else {
      setIsErrorExist(true);
      seterrorText("You have to add at least 3 reviewers");
    }
  };
  const handlePreviousPage = () => {
    stepperStore.getPreviousPage();
  };

  const handleAddReviewer = (body) => {
    let reviewerForm = {
      first_name: body.first_name,
      last_name: body.last_name,
      degree: body.degree,
      institution: body.institution,
      email: body.email,
      reason: body.reason,
    };

    const emailExists = submissionStore.reviewerList.some(
      (object) => object.email === reviewerForm.email
    );

    if (emailExists) {
      // Email already exists, return an error message
      setIsErrorExist(true);
      seterrorText("Email already exists in the list of reviewers");
      // You can handle the error as needed, e.g., return an error response to the user.
    } else {
      // Email doesn't exist, you can insert the new object into the array
      setIsErrorExist(false);
      seterrorText("");
      // Perform any other actions you need.
      submissionStore.setReviewerList(reviewerForm);
      reset();
    }
  };
  const handleDeleteReviewer = (reviewer) => {
    const index = submissionStore.reviewerList.indexOf(reviewer);
    submissionStore.deleteReviewerFromList(index);
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Add Suggest Reviewers</h5>
        <Form>
          {/*  First Reviewer*/}
          {/* {isErrorExist && ( */}
          <Form.Text className="error-text mb-6">{errorText}</Form.Text>
          {/* )} */}
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>First Name*</Form.Label>
                <Controller
                  control={control}
                  name="first_name"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "The First Name field is mandatory",
                    },
                  }}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Form.Control
                      required
                      type="text"
                      placeholder="First name"
                      {...field}
                    />
                  )}
                />
                {errors && errors?.first_name && (
                  <Form.Text className="error-text">
                    {errors.first_name.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Last Name*</Form.Label>
                <Controller
                  control={control}
                  name="last_name"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "The Last Name field is mandatory",
                    },
                  }}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Form.Control
                      required
                      type="text"
                      placeholder="Also last name"
                      {...field}
                    />
                  )}
                />
                {errors && errors?.last_name && (
                  <Form.Text className="error-text">
                    {errors.last_name.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="degree">
                <Form.Label>Degree*</Form.Label>
                <Controller
                  control={control}
                  name="degree"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "The Degree field is mandatory",
                    },
                  }}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Form.Control
                      required
                      type="text"
                      placeholder="Degree"
                      {...field}
                    />
                  )}
                />
                {errors && errors?.degree && (
                  <Form.Text className="error-text">
                    {errors.degree.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Institution*</Form.Label>
                <Controller
                  control={control}
                  name="institution"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "The Institution field is mandatory",
                    },
                  }}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Form.Control
                      required
                      type="text"
                      placeholder="Institution"
                      {...field}
                    />
                  )}
                />
                {errors && errors?.institution && (
                  <Form.Text className="error-text">
                    {errors.institution.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="degree">
                <Form.Label>Email*</Form.Label>
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "The Email field is mandatory",
                    },
                    pattern: {
                      value: patterns.regExpEmail,
                      message: "The email field should include '@' and '.'",
                    },
                  }}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Form.Control
                      required
                      type="email"
                      placeholder="Email"
                      {...field}
                    />
                  )}
                />
                {errors && errors?.email && (
                  <Form.Text className="error-text">
                    {errors.email.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Reason</Form.Label>
                <Controller
                  control={control}
                  name="reason"
                  defaultValue=""
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Reason"
                      {...field}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="mt-3 position-btn-right">
            <Button variant="primary" onClick={handleSubmit(handleAddReviewer)}>
              Add Reviewer
            </Button>
          </div>

          {submissionStore.reviewerList.length !== 0 && (
            <div>
              <h6>Reviewers List</h6>
              {submissionStore.reviewerList.map((reviewer, index) => (
                <div key={index}>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <FontAwesomeIcon
                      onClick={() => handleDeleteReviewer(reviewer)}
                      icon={faTimes}
                      style={{ cursor: "pointer" }}
                    />
                    <p></p>
                  </div>

                  <p>FirstName : {reviewer.first_name}</p>
                  <p>LastName : {reviewer.last_name}</p>
                  <p>Degree : {reviewer.degree}</p>
                  <p>Institution : {reviewer.institution}</p>
                  <p>Email : {reviewer.email}</p>
                </div>
              ))}
            </div>
          )}

          <div className="block-btns-stepper">
            <div className="mt-3">
              <Button variant="primary" onClick={handlePreviousPage}>
                Previous
              </Button>
            </div>
            <div className="mt-3">
              <Button variant="primary" onClick={handleNextPage}>
                Next
              </Button>
            </div>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
});

export default SuggestReviewers;
