import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faEye,
  faEyeSlash,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Alert,
} from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";

import * as authService from "../../services/auth.service";
import * as patterns from "../../utils/constants/patterns";

export default () => {
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Handle create new package
  const login = async (body) => {
    try {
      const result = await authService.loginService(body);
      if (result && result.access_token) {
        localStorage.setItem("ojs_home_access_token", result.access_token);
        history.push({ pathname: Routes.AllSubmissions.path });
        setIsError(false);
        setErrorMessage("");
      }
    } catch (error) {
      console.log("error ====>", error);
      // if (typeof error === "string") {
      const errorMessage = JSON.parse(error.message);
      setErrorMessage(errorMessage.message);
      // }
      // else {
      //   setErrorMessage("Something Went Wrong!");
      // }
      setIsError(true);
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          {isError && (
            <Alert
              variant={"danger"}
              style={{
                textAlign: "center",
              }}
            >
              {errorMessage}!
            </Alert>
          )}
          <p className="text-center">
            <Card.Link
              as={Link}
              to={Routes.Presentation.path}
              className="text-gray-700"
            >
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to
              homepage
            </Card.Link>
          </p>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to our platform</h3>
                </div>
                <Form className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Controller
                        control={control}
                        name="email"
                        defaultValue=""
                        rules={{
                          required: {
                            value: true,
                            message: "The email field is mandatory",
                          },
                          pattern: {
                            value: patterns.regExpEmail,
                            message:
                              "The email field should include '@' and '.'",
                          },
                        }}
                        render={({
                          field: { ref, ...field },
                          fieldState: { error },
                        }) => (
                          <Form.Control
                            autoFocus
                            required
                            type="email"
                            placeholder="example@company.com"
                            {...field}
                          />
                        )}
                      />
                    </InputGroup>
                    {errors && errors?.email && (
                      <Form.Text className="error-text">
                        {errors.email.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Controller
                          control={control}
                          name="password"
                          defaultValue=""
                          rules={{
                            required: {
                              value: true,
                              message: "The password field is mandatory",
                            },
                          }}
                          render={({
                            field: { ref, ...field },
                            fieldState: { error },
                          }) => (
                            <Form.Control
                              required
                              type={isPasswordShow ? "text" : "password"}
                              placeholder="Password"
                              {...field}
                            />
                          )}
                        />
                        <InputGroup.Text>
                          <FontAwesomeIcon
                            onClick={() => setIsPasswordShow(!isPasswordShow)}
                            icon={isPasswordShow ? faEyeSlash : faEye}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                      {errors && errors?.password && (
                        <Form.Text className="error-text">
                          {errors.password.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Form.Group>
                  <Button
                    variant="primary"
                    onClick={handleSubmit(login)}
                    className="w-100"
                  >
                    Sign in
                  </Button>
                </Form>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link
                      as={Link}
                      to={Routes.Signup.path}
                      className="fw-bold"
                    >
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    You are Reviewer?
                    <Card.Link
                      as={Link}
                      to={Routes.SigninReviewer.path}
                      className="fw-bold"
                    >
                      {` Sign In from here`}
                    </Card.Link>
                  </span>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    <Card.Link
                      as={Link}
                      to={Routes.ForgotPassword.path}
                      className="fw-bold"
                    >
                      Forget Password?
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
