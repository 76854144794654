import { makeAutoObservable } from "mobx";
import { getSumbissionById } from "../services/submission.service";
import { BASE_URL } from "../utils/constants/apis";

class SubmissionStore {
  articleType = "";
  coverLetter = "";
  maunscript = "";
  reviewerList = [];
  authorList = [];
  contributorAuthor = false;
  correspondingAuthor = false;
  selectedOptionFunding = false;
  funderName = "";
  grant = "";
  fileLink = "";
  fileId = "";
  title = "";
  abstract = "";

  constructor() {
    makeAutoObservable(this);
  }
  // add article type
  addArticleType(value) {
    this.articleType = value;
  }
  // add Cover Letter
  setCoverLetter(value) {
    this.coverLetter = value;
  }
  //add Maunscripter Letter
  setMaunscripterLetter(value) {
    this.maunscript = value;
  }
  //set Reviewer List
  setReviewerList(value) {
    this.reviewerList.push(value);
  }
  //set Reviewer List
  deleteReviewerFromList(value) {
    this.reviewerList = this.reviewerList.filter((_, i) => i !== value);
  }
  //set Reviewer List
  setAuthorList(value) {
    this.authorList.push(value);
  }
  //set Reviewer List
  deleteAuthorFromList(value) {
    this.authorList = this.authorList.filter((_, i) => i !== value);
  }
  //set correspondingAuthor
  setCorrespondingAuthor(value) {
    this.correspondingAuthor = value;
  }
  //set correspondingAuthor
  setContributorAuthor(value) {
    this.contributorAuthor = value;
  }
  //set selectedOptionFunding
  setSelectedOption(value) {
    this.selectedOptionFunding = value;
  }
  //set funderName
  setFunderName(value) {
    this.funderName = value;
  }
  //set funderName
  setGrant(value) {
    this.grant = value;
  }
  //set funderName
  setFileLink(value) {
    this.fileLink = value;
  }
  //set funderName
  setFileId(value) {
    this.fileId = value;
  }

  //set funderName
  setTitle(value) {
    this.title = value;
  }
  //set funderName
  setAbstract(value) {
    this.abstract = value;
  }
  resetFields() {
    this.addArticleType("");
    this.setCoverLetter("");
    this.setMaunscripterLetter("");
    this.reviewerList = [];
    this.authorList = [];
    this.setCorrespondingAuthor(false);
    this.setSelectedOption(false);
    this.setFunderName("");
    this.setGrant("");
    this.setFileLink("");
    this.setTitle("");
  }

  async getSubmissionById(id) {
    try {
      const result = await getSumbissionById(id);
      this.addArticleType(result.file.articleType);
      let coverLetterPayload = {
        name: result.file.coverLetter,
      };
      let manuscriptPayload = {
        name: result.file.manuscript,
      };
      this.setTitle(result.file.title);
      this.setAbstract(result.file.abstract);
      this.setCoverLetter(coverLetterPayload);
      this.setMaunscripterLetter(manuscriptPayload);
      this.reviewerList = result.reviewers;
      this.authorList = result.authors;
      this.setCorrespondingAuthor(false);
      this.setSelectedOption(result.file.isProjectFunded);
      this.setFunderName(result.file.funderName);
      this.setGrant(result.file.grantNumber);
      this.setFileLink(`${BASE_URL}/${result.file.filePath}/${result.file.mergedFile}`);
    } catch (error) {}
  }

  async getSumbissionFileById(id) {
    try {
      return await getSumbissionById(id);
    } catch (error) {}
  }
}

export default new SubmissionStore();
