import React, { useState } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  FormCheck,
  Alert,
} from "@themesberg/react-bootstrap";
import { observer } from "mobx-react";
import { Controller, useForm } from "react-hook-form";

import { useGlobalContext } from "../../context/useGlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import * as submissionService from "../../services/submission.service";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";

const SubmissionDeclaration = observer(() => {
  const { stepperStore, submissionStore } = useGlobalContext();
  const [isSucces, setIsSucces] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);
  const history = useHistory();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleNextPage = async () => {
    let payload = {
      articleType: submissionStore.articleType,
      previouslyPublished: true,
      underConsiderationElsewhere: true,
      conflictOfInterest: true,
      allAuthorsApproved: true,
      authors: submissionStore.authorList,
      reviewers: submissionStore.reviewerList,
      isProjectFunded: submissionStore.selectedOptionFunding,
      funderName: submissionStore.funderName,
      grantNumber: submissionStore.grant,
    };
    try {
      setIsButtonEnabled(true);
      await submissionService.addSubmission(payload, submissionStore.fileId);
      setIsSucces(true);
      setIsButtonEnabled(false);

      submissionStore.setFileId("");
      setTimeout(function () {
        history.push({ pathname: Routes.AllSubmissions.path });
      }, 5000);
    } catch (error) {}
  };
  const handlePreviousPage = () => {
    stepperStore.getPreviousPage();
  };

  const handleSendEmail = async () => {
    try {
      await submissionService.sendEmailSumbission(submissionStore.fileId);
      setIsButtonEnabled(false);
    } catch (error) {}
  };

  return (
    <>
      {isSucces && (
        <Alert
          variant={"success"}
          style={{
            textAlign: "center",
          }}
        >
          You succesufly submit the file! Please check your email
        </Alert>
      )}
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <h5 className="mb-4">Submission Declaration</h5>
          <h6 className="mb-4">The submission of a manuscript implies that:</h6>
          <Form>
            <Row>
              <FormCheck type="checkbox" className="mt-3 mb-3">
                <Controller
                  control={control}
                  name="manuscriptNotPublish"
                  defaultValue={false}
                  rules={{
                    required: "You must agree to this statement",
                  }}
                  render={({ field }) => (
                    <FormCheck.Input
                      type="checkbox"
                      id="defaultCheck5"
                      className="me-2"
                      {...field}
                    />
                  )}
                />
                <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">
                  The manuscript has not been previously published.
                </FormCheck.Label>
              </FormCheck>
              {errors && errors?.manuscriptNotPublish && (
                <Form.Text className="error-text">
                  {errors.manuscriptNotPublish.message}
                </Form.Text>
              )}
              <FormCheck type="checkbox" className="mt-3 mb-3">
                <Controller
                  control={control}
                  name="underConsideration"
                  defaultValue={false}
                  rules={{
                    required: "You must agree to this statement",
                  }}
                  render={({ field }) => (
                    <FormCheck.Input
                      type="checkbox"
                      id="defaultCheck5"
                      className="me-2"
                      {...field}
                    />
                  )}
                />
                <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">
                  Neither the manuscript nor any parts of its content are at
                  present under consideration in another journal
                </FormCheck.Label>
              </FormCheck>
              {errors && errors?.underConsideration && (
                <Form.Text className="error-text">
                  {errors.underConsideration.message}
                </Form.Text>
              )}
              <FormCheck type="checkbox" className="mt-3 mb-3">
                <Controller
                  control={control}
                  name="authorDecalration"
                  defaultValue={false}
                  rules={{
                    required: "You must agree to this statement",
                  }}
                  render={({ field }) => (
                    <FormCheck.Input
                      type="checkbox"
                      id="defaultCheck5"
                      className="me-2"
                      {...field}
                    />
                  )}
                />
                <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">
                  Authors decalre that they have no conflict of interset do
                  disclose.
                </FormCheck.Label>
              </FormCheck>
              {errors && errors?.authorDecalration && (
                <Form.Text className="error-text">
                  {errors.authorDecalration.message}
                </Form.Text>
              )}
              <FormCheck type="checkbox" className="mt-3 mb-3">
                <Controller
                  control={control}
                  name="publicationEthics"
                  defaultValue={false}
                  rules={{
                    required: "You must agree to this statement",
                  }}
                  render={({ field }) => (
                    <FormCheck.Input
                      type="checkbox"
                      id="defaultCheck5"
                      className="me-2"
                      {...field}
                    />
                  )}
                />
                <FormCheck.Label className="mb-0">
                  all authors have approved the manuscript and agree with its
                  submission. Auhtors accpet the{" "}
                  <span className="cursor-pointer">Publication ethics.</span>
                </FormCheck.Label>
              </FormCheck>
              {errors && errors?.publicationEthics && (
                <Form.Text className="error-text">
                  {errors.publicationEthics.message}
                </Form.Text>
              )}

              <h6 className="mb-4 mt-4" style={{ display: "flex" }}>
                <p>
                  * Please download your PDF file at the foot of this page and
                  make sure that it is complete.
                </p>
                <a
                  onClick={handleSendEmail}
                  href={submissionStore.fileLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  <FontAwesomeIcon
                    style={{ marginLeft: 10, cursor: "pointer" }}
                    icon={faDownload}
                  />
                </a>
              </h6>
              <h6 className="mb-4 mt-1">
                * You can go back and edit your submission.
              </h6>
              <h6 className="mb-4 mt-1">
                * To complete your submission, please click 'Submit'.
              </h6>
            </Row>

            <div className="block-btns-stepper">
              <div className="mt-3">
                <Button variant="primary" onClick={handlePreviousPage}>
                  Previous
                </Button>
              </div>
              <div className="mt-3">
                <Button
                  disabled={isButtonEnabled}
                  onClick={handleSubmit(handleNextPage)}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
});

export default SubmissionDeclaration;
