import React from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  FormCheck,
} from "@themesberg/react-bootstrap";
import { observer } from "mobx-react";

import { useGlobalContext } from "../../context/useGlobalContext";
import { useState } from "react";

const Funding = observer(() => {
  const { stepperStore, submissionStore } = useGlobalContext();

  const handleNextPage = () => {
    stepperStore.getNextPage();
  };
  const handlePreviousPage = () => {
    stepperStore.getPreviousPage();
  };
  const handleOptionChange = (value) => {
    submissionStore.setSelectedOption(value);
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Funding</h5>
        <h6 className="mb-4">
          A funding has been provided for the submitted research work
        </h6>
        <Row>
          <Form.Group>
            <div>
              <Form.Check
                type="radio"
                id="defaultCheck5"
                className="me-2"
                checked={submissionStore.selectedOptionFunding === true}
                onChange={() => handleOptionChange(true)}
                label="Yes"
              />
              <Form.Check
                type="radio"
                id="defaultCheck6"
                checked={submissionStore.selectedOptionFunding === false}
                onChange={() => handleOptionChange(false)}
                label="No"
                className="mb-0"
              />
            </div>
          </Form.Group>
        </Row>
        <Form>
          {/*  First Reviewer*/}
          <h6 className="mb-4 mt-4">Add a funder (if present)</h6>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Funder Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Funder name"
                  defaultValue={submissionStore.funderName}
                  onChange={(e) =>
                    submissionStore.setFunderName(e.target.value)
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Grant / Award Number</Form.Label>
                <Form.Control
                  defaultValue={submissionStore.grant}
                  type="text"
                  placeholder="Award number"
                  onChange={(e) => submissionStore.setGrant(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="block-btns-stepper">
            <div className="mt-3">
              <Button variant="primary" onClick={handlePreviousPage}>
                Previous
              </Button>
            </div>
            <div className="mt-3">
              <Button variant="primary" onClick={handleNextPage}>
                Next
              </Button>
            </div>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
});

export default Funding;
