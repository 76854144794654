import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { Routes } from "../routes";

// pages
import Presentation from "./Presentation";
import Upgrade from "./Upgrade";
import DashboardOverview from "./dashboard/DashboardOverview";
import AllSubmissions from "./Transactions";
import Settings from "./Settings";
import BootstrapTables from "./tables/BootstrapTables";
import Signin from "./examples/Signin";
import Signup from "./examples/Signup";
import ForgotPassword from "./examples/ForgotPassword";
import ResetPassword from "./examples/ResetPassword";
import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";

// documentation pages
import DocsOverview from "./documentation/DocsOverview";
import DocsDownload from "./documentation/DocsDownload";
import DocsQuickStart from "./documentation/DocsQuickStart";
import DocsLicense from "./documentation/DocsLicense";
import DocsFolderStructure from "./documentation/DocsFolderStructure";
import DocsBuild from "./documentation/DocsBuild";
import DocsChangelog from "./documentation/DocsChangelog";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";
import StepperComponent from "./Stepper";
import AimAndScop from "./AimAndScop";
import PrivacyStatement from "./PrivacyStatement";
import Publisher from "./Publisher";
import EditorialBoard from "./EditorialBoard";
import OnlineSubmission from "./OnlineSubmission";
import PublicationEthics from "./PublicationEthics";
import GuidelineAuthor from "./GuidelineAuthor";
import GuidelineReviewer from "./GuidelineReviewer";
import GuidelineAssociateEditor from "./GuidelineAssociateEditor";
import Issues from "./Issues";
import StepperEditComponent from "./StepperEdit";
import { useGlobalContext } from "../context/useGlobalContext";
import * as userService from "../services/user.service";
import { observer } from "mobx-react";
import VerifyAccount from "./examples/VerifyAccount";
import AddReview from "./Reviewers/addReview";
import SignupReviewer from "./examples/Signup-reviewer";
import DeclinedPage from "./examples/DeclinedPage";
import SigninReviwer from "./examples/Signin-reviwer";
import IssuesDetails from "./IssuesDetails";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {" "}
          <Preloader show={loaded ? false : true} /> <Component {...props} />{" "}
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />
          <Sidebar />

          <main className="content">
            <Navbar />
            <Component {...props} />
            <Footer
              toggleSettings={toggleSettings}
              showSettings={showSettings}
            />
          </main>
        </>
      )}
    />
  );
};

const Index = observer(() => {
  const history = useHistory();
  const { userStore } = useGlobalContext();

  useEffect(() => {
    if (window.location.pathname === Routes.ResetPassword.path) {
      history.push({ pathname: Routes.ResetPassword.path });
    }
  }, []);

  const getUserInfo = async () => {
    try {
      const result = await userService.getUserInfo();
      userStore.setUserInfo(result);
    } catch (error) {}
  };
  useEffect(() => {
    if (localStorage.getItem("ojs_home_access_token")) {
      getUserInfo();
    }
  }, []);

  return (
    <Switch>
      <RouteWithLoader
        exact
        path={Routes.Presentation.path}
        component={Presentation}
      />
      <RouteWithLoader
        exact
        path={Routes.AimAndScop.path}
        component={AimAndScop}
      />
      <RouteWithLoader
        exact
        path={Routes.PrivacyStatement.path}
        component={PrivacyStatement}
      />
      <RouteWithLoader
        exact
        path={Routes.Publisher.path}
        component={Publisher}
      />
      <RouteWithLoader
        exact
        path={Routes.EditorialBoard.path}
        component={EditorialBoard}
      />
      <RouteWithLoader
        exact
        path={Routes.OnlineSubmission.path}
        component={OnlineSubmission}
      />
      <RouteWithLoader
        exact
        path={Routes.PublicationEthics.path}
        component={PublicationEthics}
      />
      <RouteWithLoader
        exact
        path={Routes.GuidelineAuthor.path}
        component={GuidelineAuthor}
      />
      <RouteWithLoader
        exact
        path={Routes.GuidelineReviewer.path}
        component={GuidelineReviewer}
      />
      <RouteWithLoader
        exact
        path={Routes.GuidelineAssociateEditor.path}
        component={GuidelineAssociateEditor}
      />
      <RouteWithLoader exact path={Routes.Issues.path} component={Issues} />
      <RouteWithLoader exact path={Routes.IssueDetails.path} component={IssuesDetails} />

      <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
      <RouteWithLoader
        exact
        path={Routes.SigninReviewer.path}
        component={SigninReviwer}
      />
      <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
      <RouteWithLoader
        exact
        path={Routes.SignupReviewer.path}
        component={SignupReviewer}
      />
      <RouteWithLoader
        exact
        path={Routes.ForgotPassword.path}
        component={ForgotPassword}
      />
      <RouteWithLoader
        exact
        path={Routes.ResetPassword.path}
        component={ResetPassword}
      />
      <RouteWithLoader
        exact
        path={Routes.VerifyAccount.path}
        component={VerifyAccount}
      />
      <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
      <RouteWithLoader
        exact
        path={Routes.DeclinedPage.path}
        component={DeclinedPage}
      />
      <RouteWithLoader
        exact
        path={Routes.NotFound.path}
        component={NotFoundPage}
      />
      <RouteWithLoader
        exact
        path={Routes.ServerError.path}
        component={ServerError}
      />



      {/* pages */}
      <RouteWithSidebar
        exact
        path={Routes.Submission.path}
        component={StepperComponent}
      />
      <RouteWithSidebar
        exact
        path={Routes.EditSubmission.path}
        component={StepperEditComponent}
      />
      <RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
      <RouteWithSidebar
        exact
        path={Routes.AllSubmissions.path}
        component={AllSubmissions}
      />
      <RouteWithSidebar
        exact
        path={Routes.AddReview.path}
        component={AddReview}
      />
      <RouteWithSidebar
        exact
        path={Routes.Settings.path}
        component={Settings}
      />
      <RouteWithSidebar
        exact
        path={Routes.BootstrapTables.path}
        component={BootstrapTables}
      />

      {/* components */}
      <RouteWithSidebar
        exact
        path={Routes.Accordions.path}
        component={Accordion}
      />
      <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
      <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
      <RouteWithSidebar
        exact
        path={Routes.Breadcrumbs.path}
        component={Breadcrumbs}
      />
      <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
      <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
      <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
      <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
      <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
      <RouteWithSidebar
        exact
        path={Routes.Pagination.path}
        component={Pagination}
      />
      <RouteWithSidebar
        exact
        path={Routes.Popovers.path}
        component={Popovers}
      />
      <RouteWithSidebar
        exact
        path={Routes.Progress.path}
        component={Progress}
      />
      <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
      <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
      <RouteWithSidebar
        exact
        path={Routes.Tooltips.path}
        component={Tooltips}
      />
      <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />

      {/* documentation */}
      <RouteWithSidebar
        exact
        path={Routes.DocsOverview.path}
        component={DocsOverview}
      />
      <RouteWithSidebar
        exact
        path={Routes.DocsDownload.path}
        component={DocsDownload}
      />
      <RouteWithSidebar
        exact
        path={Routes.DocsQuickStart.path}
        component={DocsQuickStart}
      />
      <RouteWithSidebar
        exact
        path={Routes.DocsLicense.path}
        component={DocsLicense}
      />
      <RouteWithSidebar
        exact
        path={Routes.DocsFolderStructure.path}
        component={DocsFolderStructure}
      />
      <RouteWithSidebar
        exact
        path={Routes.DocsBuild.path}
        component={DocsBuild}
      />
      <RouteWithSidebar
        exact
        path={Routes.DocsChangelog.path}
        component={DocsChangelog}
      />

      <Redirect to={Routes.NotFound.path} />
    </Switch>
  );
});

export default Index;
