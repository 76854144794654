import React from "react";
import { Step, Stepper } from "react-form-stepper";
import ArticleType from "./StepperComponents/articleType";
import UploadFile from "./StepperComponents/uploadFile";
import { useState } from "react";
import SuggestReviewers from "./StepperComponents/suggestReviewers";
import AddAuthors from "./StepperComponents/addAuthors";
import Funding from "./StepperComponents/funding";
import SubmissionDeclaration from "./StepperComponents/submissionDeclaration";
import { useGlobalContext } from "../context/useGlobalContext";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const StepperEditComponent = observer(() => {
  const { stepperStore, submissionStore } = useGlobalContext();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const getSubmissionById = async () => {
    try {
      await submissionStore.getSubmissionById(id);
      setIsLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    getSubmissionById();
  }, []);

  const redirectToSetp = (step) => {
    stepperStore.setActiveStep(step);
  };

  return (
    !isLoading && (
      <div>
        <Stepper activeStep={stepperStore.activeStep}>
          <Step
            label="Article Type"
            onClick={() => redirectToSetp(0)}
            disabled={false}
          />
          <Step
            label="Upload your files"
            onClick={() => redirectToSetp(1)}
            disabled={false}
          />
          <Step
            label="Suggest Reviewers"
            onClick={() => redirectToSetp(2)}
            disabled={false}
          />
          <Step
            label="Add Authors"
            onClick={() => redirectToSetp(3)}
            disabled={false}
          />
          <Step
            label="Funding"
            onClick={() => redirectToSetp(4)}
            disabled={false}
          />
          <Step
            label="Submission Declaration"
            onClick={() => redirectToSetp(5)}
            disabled={false}
          />
        </Stepper>
        <div>
          {stepperStore.activeStep === 0 && <ArticleType />}
          {stepperStore.activeStep === 1 && <UploadFile />}
          {stepperStore.activeStep === 2 && <SuggestReviewers />}
          {stepperStore.activeStep === 3 && <AddAuthors />}
          {stepperStore.activeStep === 4 && <Funding />}
          {stepperStore.activeStep === 5 && <SubmissionDeclaration />}
        </div>
      </div>
    )
  );
});

export default StepperEditComponent;
