import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faFolder,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Image,
  Button,
  Container,
  Tooltip,
  OverlayTrigger,
  Navbar,
  Nav,
  NavDropdown,
} from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { Routes } from "../routes";
import OJS_LOGO from "../assets/img/logo_ojs.png";

import { useGlobalContext } from "../context/useGlobalContext";

export default () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownSubmission, setShowDropdownSubmission] = useState(false);
  const { userStore } = useGlobalContext();
  const history = useHistory();

  const handleMouseEnter = () => {
    setShowDropdownSubmission(false);
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };
  const handleMouseEnterSubmission = () => {
    setShowDropdown(false);
    setShowDropdownSubmission(true);
  };

  const handleMouseLeaveSubmission = () => {
    setShowDropdownSubmission(false);
  };
  const PagePreview = (props) => {
    const { name, image, link } = props;

    return (
      <Col xs={6} className="mb-5">
        <Card.Link
          as={Link}
          to={link}
          className="page-preview page-preview-lg scale-up-hover-2"
        >
          <Image
            src={image}
            className="shadow-lg rounded scale"
            alt="Dashboard page preview"
          />

          <div className="text-center show-on-hover">
            <h6 className="m-0 text-center text-white">
              {name}{" "}
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
            </h6>
          </div>
        </Card.Link>
      </Col>
    );
  };

  const Feature = (props) => {
    const { title, description, icon } = props;

    return (
      <Col xs={12} sm={6} lg={3}>
        <Card className="bg-white shadow-soft text-primary rounded mb-4">
          <div className="px-3 px-lg-4 py-5 text-center">
            <span className="icon icon-lg mb-4">
              <FontAwesomeIcon icon={icon} />
            </span>
            <h5 className="fw-bold text-primary">{title}</h5>
            <p>{description}</p>
          </div>
        </Card>
      </Col>
    );
  };

  const FolderItem = (props) => {
    const { name, icon, tooltip, iconColor } = props;
    const color = iconColor ? `text-${iconColor}` : "";

    return (
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="left"
        overlay={<Tooltip>{tooltip}</Tooltip>}
      >
        <li
          data-toggle="tooltip"
          data-placement="left"
          title="Main folder that you will be working with"
        >
          <FontAwesomeIcon
            icon={icon ? icon : faFolder}
            className={`${color} me-2`}
          />{" "}
          {name}
        </li>
      </OverlayTrigger>
    );
  };

  const redirectToPath = (path) => {
    history.push({ pathname: path });
  };

  return (
    <>
      <Navbar
        variant="dark"
        expand="lg"
        bg="dark"
        className="navbar-transparent navbar-theme-primary sticky-top"
      >
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand
            as={HashLink}
            to="#home"
            className="me-lg-3 d-flex align-items-center"
          >
            <Image src={OJS_LOGO} className="logo-size" />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="ms-2 brand-text d-none d-md-inline">
                Biology Analysis
              </span>
              <span className="ms-2 brand-text d-none d-md-inline">
                And Evaluation
              </span>
            </div>
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            <Navbar.Collapse id="navbar-default-primary">
              <Nav className="navbar-nav-hover align-items-lg-center">
                <Nav.Link as={Link} to={Routes.Presentation.path}>
                  Home
                </Nav.Link>

                <NavDropdown
                  title={
                    <>
                      About The Journal <FontAwesomeIcon icon={faChevronDown} />
                    </>
                  }
                  id="pages-dropdown"
                  show={showDropdown}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <NavDropdown.Item
                    className="nav--sub-link-home"
                    as={HashLink}
                    to={Routes.Presentation.path}
                  >
                    Aim And Scopes
                  </NavDropdown.Item>
                  <NavDropdown.Item as={HashLink} to={Routes.Presentation.path}>
                    Call For Paper
                  </NavDropdown.Item>
                  <NavDropdown.Item as={HashLink} to={Routes.Presentation.path}>
                    Publisher
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={Routes.PrivacyStatement.path}>
                    Privacy Statement
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  as={Link}
                  to={Routes.EditorialBoard.path}
                  className="d-sm-none d-xl-inline"
                >
                  Editorial Board
                </Nav.Link>
                <NavDropdown
                  title={
                    <>
                      Submit Your Manuscript{" "}
                      <FontAwesomeIcon icon={faChevronDown} />
                    </>
                  }
                  id="pages-dropdown"
                  show={showDropdownSubmission}
                  onMouseEnter={handleMouseEnterSubmission}
                  onMouseLeave={handleMouseLeaveSubmission}
                >
                  {!localStorage.getItem("ojs_home_access_token") && (
                    <NavDropdown.Item as={Link} to={Routes.Signin.path}>
                      Online Submission
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item
                    as={Link}
                    to={Routes.PublicationEthics.path}
                  >
                    Publication ethics
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={Routes.GuidelineAuthor.path}>
                    Guideline for author
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={Routes.GuidelineReviewer.path}
                  >
                    Guideline for reviewer
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={Routes.GuidelineAssociateEditor.path}
                  >
                    Guideline for associate editor
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to={Routes.Issues.path}>
                  Issues
                </Nav.Link>
                <Nav.Link as={Link} to={Routes.Presentation.path}>
                  Contact
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            {localStorage.getItem("ojs_home_access_token") ? (
              userStore.userInfo && (
                <div
                  className="media d-flex align-items-center cursor-pointer"
                  onClick={() => redirectToPath(Routes.AllSubmissions.path)}
                >
                  {/* <Image
                    src={`https://www.gravatar.com/avatar/${userStore.userInfo.email}?d=identicon`}
                    className="user-avatar md-avatar rounded-circle"
                  /> */}
                  <div className="media-body ms-2 text-white align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">
                      {userStore.userInfo.first_name}{" "}
                      {userStore.userInfo.last_name}
                    </span>
                  </div>
                </div>
              )
            ) : (
              <>
                <Button
                  variant="outline-white"
                  as={Link}
                  to={Routes.Signin.path}
                  className="ms-3"
                >
                  Sign In
                </Button>
              </>
            )}
          </div>
        </Container>
      </Navbar>
      {/* pt-5 pt-lg-6 pb-9 pb-lg-12 */}
      <section
        className="section-header overflow-hidden pt-5 pt-lg-6 pb-9 pb-lg-4 bg-primary text-white"
        id="home"
      >
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <div className="react-big-icon d-none d-lg-block">
                <span className="fab fa-react"></span>
              </div>
              <h1 className="fw-bolder text-secondary">Guideline for author</h1>
              <p className="text-muted fw-light mb-5 h5">
                Guideline for author Section
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section
        className="section section-md bg-soft mt-5 pt-lg-3"
        id="features"
      >
        <Container>
          <Row className="justify-content-between align-items-center mb-2 mb-lg-4">
            <Col lg={8} className="order-lg-2 mb-2 mb-lg-0">
              <h2>Introduction</h2>
              <p className="mb-3 lead fw-bold">
                Biology Analysis and Evaluation is an open-access journal that
                currently has no publication charges to the authors and is also
                free for readers.
              </p>
              <p className="mb-4">
                Biology Analysis and Evaluation publishes original articles,
                reviewers, short communications, perspectives, and conferences
                that are consistent with its scopes and are recommended by its
                referee.
              </p>
              <ul>
                <li>
                  The original article should present important original
                  research results with relevant research objectives. Clear
                  experimental design with quantitative results and statistical
                  analysis are needed. The qualitative studies should include
                  explanations of case studies, focus groups, and interviews.{" "}
                </li>
                <li>
                  Reviewers should provide a comprehensive survey of a broad
                  topic of research and may state the authors’ own opinion.
                </li>
                <li>
                  Short communications: 3,000 words describing preliminary work
                  results with merits immediate publication. Short
                  communications should not contain more than 5 tables and
                  figures combined and no more than 50 references.
                </li>
                <li>
                  Perspectives 2000 to 3000 words describing recently published
                  papers or a high-interest current research topic. Perspectives
                  should have a short pithy title, an abstract of 50 words or
                  less, no more than 35 references, and 1 or 2 figures (with
                  figure legends) or tables.
                </li>
              </ul>
              <p className="mb-4">
                All the manuscripts submitted to Biology Analysis and Evaluation
                are peer-reviewed according to the
                <span className="underline-text">
                  {" "}
                  <Card.Link as={Link} to={Routes.EditorialBoard.path}>
                    Editorial and Peer Review Processes.
                  </Card.Link>
                </span>
              </p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-between align-items-center mb-2 mb-lg-4">
            <Col lg={8} className="order-lg-2 mb-2 mb-lg-0">
              <h2>Submission declaration</h2>
              <p className="mb-4">
                The submission of a manuscript implies that:
              </p>
              <ul>
                <li>The manuscript has not been previously published.</li>
                <li>
                  Neither the manuscript nor any parts of its content are at
                  present under consideration in another journal.
                </li>
                <li>
                  Authors declare that they have no conflict of interest to
                  disclose.
                </li>
                <li>
                  All authors have approved the manuscript and agree with its
                  submission.
                </li>
                <li>
                  Authors accept the{" "}
                  <span className="underline-text">
                    {" "}
                    <Card.Link as={Link} to={Routes.PublicationEthics.path}>
                      Publication ethics.
                    </Card.Link>{" "}
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-between align-items-center mb-2 mb-lg-4">
            <Col lg={8} className="order-lg-2 mb-2 mb-lg-0">
              <h2>Copyright Notice</h2>
              <p className="mb-4">
                Each paper published in Biology Analysis and Evaluation is
                distributed under the terms of{" "}
                <span className="underline-text-black">
                  {" "}
                  Creative Commons Attribution-ShareAlike 4.0 International
                </span>{" "}
                (CC BY-SA 4.0) which allows others to share the work with an
                acknowledgment of the work's authorship and initial publication
                in this journal.
              </p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-between align-items-center mb-2 mb-lg-4">
            <Col lg={8} className="order-lg-2 mb-2 mb-lg-0">
              <h2>Article submission</h2>
              <p className="mb-4">
                The article should be submitted online through the web portal{" "}
                <span className="underline-text">
                  <Card.Link as={Link} to={Routes.Signin.path}>
                    https://baejournal.com/#/sign-in.
                  </Card.Link>
                </span>{" "}
                When submitting the article, the corresponding author should
                include all the eligible co-authors.
              </p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-between align-items-center mb-2 mb-lg-4">
            <Col lg={8} className="order-lg-2 mb-2 mb-lg-0">
              <h2>File Formats</h2>
              <p className="mb-4">
                Biology Analysis and Evaluation accepts Microsoft Word (DOC,
                DOCX) as the format for their file submission. Authors are
                encouraged to use the{" "}
                <span
                  className="cursor-pointer underline-text"
                  onClick={() => {
                    window.open(
                      "https://api.baejournal.com/files/template_BAE.pdf",
                      "_blank"
                    );
                  }}
                >
                  submission template{" "}
                </span>{" "}
                to organize their manuscript as a single file. Please add your
                tables and graphics in the main text after the paragraph of
                their first citation.
              </p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-between align-items-center mb-2 mb-lg-4">
            <Col lg={8} className="order-lg-2 mb-2 mb-lg-0">
              <h2>Article structure</h2>
              <ul>
                <li>
                  <span className="bold-text">Title:</span> It should be concise
                  and informative without abbreviations.
                </li>
                <li>
                  <span className="bold-text">Author’s affiliation:</span>{" "}
                  Authors list should include names, affiliations, emails,
                  institutions, universities, cities, states, and countries. The
                  corresponding author(s) should be designed. The email
                  addresses of the corresponding author(s) will be displayed on
                  the published paper. Equal Contributions: authors should also
                  be marked with an aster (*) and the following statement should
                  be added: “These authors contributed equally to this work and
                  manuscript”.
                </li>
                <li>
                  <span className="bold-text">Abstract:</span> The Abstract
                  should be clear, precise, brief, and presented as a single
                  paragraph. It must include the work’s context and aims, a
                  brief description of the methods, and the main findings. It
                  should also include the relevant interpretations and
                  conclusions described in the main text.
                </li>
                <li>
                  <span className="bold-text">Keywords:</span> 4 to 6 keywords
                  specific to the article subject should be inserted into the
                  manuscript after the abstract. Keywords should be separated
                  with semicolons.
                </li>
                <li>
                  <span className="bold-text">Abbreviations:</span> A
                  description of the abbreviations that are not standard in the
                  studied field must be included in a separate section.
                  Abbreviations will appear on the first page of the article.
                </li>
                <li>
                  <span className="bold-text">Acknowledgements:</span> In a
                  separate section, the authors could list those individuals who
                  provided help during the research. If funding has been
                  provided for the submitted research work, it is recommended to
                  include the funder’s name and the award number in the
                  acknowledgment section.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-between align-items-center mb-2 mb-lg-4">
            <Col lg={8} className="order-lg-2 mb-2 mb-lg-0">
              <h2>The main text</h2>
              <ul>
                <li>
                  <span className="bold-text">Introduction:</span> It should
                  provide an adequate background and the expected methodology or
                  result. It should also state the aims of the work.
                </li>
                <li>
                  <span className="bold-text">Material and methods:</span>{" "}
                  Provide sufficient details to allow the work to be reproduced
                  by an independent researcher. Methods that are already
                  published should be summarized, and indicated by a reference.
                  If quoting directly from a previously published method, use
                  quotation marks and also cite the source. Any modifications to
                  existing methods should also be described.
                </li>
                <li>
                  <span className="bold-text">Results:</span> Provide clear and
                  concise results.
                </li>
                <li>
                  <span className="bold-text">Discussion:</span> This section
                  should discuss the significance of the results present in the
                  manuscript. A combined Results and Discussion section is also
                  accepted.
                </li>
                <li>
                  <span className="bold-text">Conclusions:</span> A separate
                  paragraph containing the main conclusions should be given.
                </li>
                <li>
                  <span className="bold-text">Figures:</span> Figures should be
                  high-quality images (.JPG, .TIF .PNG). Figures should be
                  numbered using Arabic numerals and cited in the manuscript in
                  consecutive numerical order (e.g. Fig 1, or Fig. 1A). Each
                  figure should have a caption explaining its components and
                  refer to the main text.
                </li>
                <li>
                  <span className="bold-text">Tables:</span> Provide tables as
                  editable text and not as images. Tables should be numbered
                  using Arabic numerals and cited in the manuscript in
                  consecutive numerical order (e.g. Table 1). Each table should
                  have a caption explaining its components.
                </li>
                <li>
                  <span className="bold-text">References:</span>All references
                  cited in the text should be present in the reference list. To
                  avoid typing mistakes and duplicated references, it is
                  recommended to prepare references with a bibliography software
                  package. The list of references should be arranged
                  alphabetically and chronologically.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-between align-items-center mb-2 mb-lg-4">
            <Col lg={8} className="order-lg-2 mb-2 mb-lg-0">
              <h2>Reference format </h2>
              <p className="mb-4">
                In the manuscript, bibliographic references should be listed as
                follows:
              </p>
              <ul>
                <li>(Name1 et al. 2019; Name2 et al. 2019).</li>
                <li>(Name1 and Name2, 2017a; Name1 and Name2, 2017b).</li>
                <li>If 2 authors only: (Name1 and Name2, 2019).</li>
                <li>If 3 or more authors: Name 1 et al. (2019).</li>
              </ul>
              <p className="mb-4">
                The reference list should be formatted as follows:
              </p>
              <span className="bold-text">A journal article </span>
              <p className="mb-4">
                Name1, A. B.; Name2, C.D.; Name3, E.F. Year. Title of the
                article. Journal, volume, pages. https://doi.org/xxxxxxxxxxx
              </p>
              <span className="bold-text">Conference Proceedings </span>
              <p className="mb-4">
                Name1, A. B.; Name2, C.D.; Name3, E.F. Year. Title of
                Presentation. In Title of the Collected Work, Proceedings of
                (the Name of the Conference), Location of Conference, Country,
                Date of Conference. Publisher: City, Country.
              </p>
              <span className="bold-text">Books and book chapters </span>
              <p className="mb-4">
                Name1, A. B.; Name2, C.D.; Name3, E.F. Year. Book title, City,
                Publisher. Name1, A. B.; Name2, C.D.; Name3, E.F. Year. Title of
                the chapter. In Book, pages range, City, Publisher.
              </p>
              <span className="bold-text">Thesis </span>
              <p className="mb-4">
                Name 1, A.B. Title of Thesis. Level of Thesis, Degree-Granting
                University, Location of University, Date of Completion.
              </p>
              <span className="bold-text">Website </span>
              <p className="mb-4">Title of the website, year. full URL.</p>
              <span className="bold-text">Supplementary material </span>
              <p className="mb-4">
                Supplementary material should be submitted together with the
                article. Each submitted Supplementary material should include a
                clear and descriptive caption.
              </p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-between align-items-center mb-2 mb-lg-4">
            <Col lg={8} className="order-lg-2 mb-2 mb-lg-0">
              <h2>Reviewer Suggestions</h2>
              <p className="mb-4">
                When submitting your manuscript, three reviewers who have
                appropriate expertise must be suggested. The suggested reviewers
                should not include current coauthors or any collaborative
                persons. Reasons for each suggestion may be added. Please note
                that your suggestion may not be used by the editor, but could
                speed up the designation of suitable reviewers.
              </p>
              <span className="bold-text">Technical support:</span>
              <p className="mb-4 underline-text">
                editorialsecretary@baejournal.com
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <footer className="footer py-6 bg-dark text-white">
        <Container>
          <Row>
            <Col md={4}>
              <Navbar.Brand
                as={HashLink}
                to="#home"
                className="me-lg-3 mb-3 d-flex align-items-center"
              >
                <Image src={OJS_LOGO} />
                <span className="ms-2 brand-text">
                  Biology Analysis and Evaluation
                </span>
              </Navbar.Brand>
              <p>
                Is an international, peer-reviewed, open access journal of
                biological sciences.
              </p>
            </Col>
            <Col xs={6} md={4} className="mb-5 mb-lg-0">
              <span className="h5">Biol. Anal. Eval</span>
              <ul className="links-vertical mt-2">
                <li>
                  <Card.Link as={HashLink} to="#abbreviation">
                    Abbreviation
                  </Card.Link>
                </li>
                <li>
                  <Card.Link as={HashLink} to="#aimAndScope">
                    Aim And Scopes
                  </Card.Link>
                </li>
                <li>
                  <Card.Link as={HashLink} to="#callForPaper">
                    Call For Paper
                  </Card.Link>
                </li>
                <li>
                  <Card.Link as={HashLink} to="#publisher">
                    Publisher
                  </Card.Link>
                </li>
              </ul>
            </Col>
          </Row>
          <hr className="bg-gray my-5" />
          <Row>
            <Col className="mb-md-2">
              <div
                className="d-flex text-center justify-content-center align-items-center"
                role="contentinfo"
              >
                <p className="font-weight-normal font-small mb-0">
                  Copyright © Biology Analysis and Evaluation{" "}
                  <span className="current-year">2023</span>. All rights
                  reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
