import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faFolder,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Image,
  Button,
  Container,
  Tooltip,
  OverlayTrigger,
  Navbar,
  Nav,
  NavDropdown,
} from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { Routes } from "../routes";
import OJS_LOGO from "../assets/img/logo_ojs.png";
import { useGlobalContext } from "../context/useGlobalContext";

export default () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownSubmission, setShowDropdownSubmission] = useState(false);
  const { userStore } = useGlobalContext();
  const history = useHistory();

  const handleMouseEnter = () => {
    setShowDropdownSubmission(false);
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };
  const handleMouseEnterSubmission = () => {
    setShowDropdown(false);
    setShowDropdownSubmission(true);
  };

  const handleMouseLeaveSubmission = () => {
    setShowDropdownSubmission(false);
  };
  const PagePreview = (props) => {
    const { name, image, link } = props;

    return (
      <Col xs={6} className="mb-5">
        <Card.Link
          as={Link}
          to={link}
          className="page-preview page-preview-lg scale-up-hover-2"
        >
          <Image
            src={image}
            className="shadow-lg rounded scale"
            alt="Dashboard page preview"
          />

          <div className="text-center show-on-hover">
            <h6 className="m-0 text-center text-white">
              {name}{" "}
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
            </h6>
          </div>
        </Card.Link>
      </Col>
    );
  };

  const Feature = (props) => {
    const { title, description, icon } = props;

    return (
      <Col xs={12} sm={6} lg={3}>
        <Card className="bg-white shadow-soft text-primary rounded mb-4">
          <div className="px-3 px-lg-4 py-5 text-center">
            <span className="icon icon-lg mb-4">
              <FontAwesomeIcon icon={icon} />
            </span>
            <h5 className="fw-bold text-primary">{title}</h5>
            <p>{description}</p>
          </div>
        </Card>
      </Col>
    );
  };

  const FolderItem = (props) => {
    const { name, icon, tooltip, iconColor } = props;
    const color = iconColor ? `text-${iconColor}` : "";

    return (
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="left"
        overlay={<Tooltip>{tooltip}</Tooltip>}
      >
        <li
          data-toggle="tooltip"
          data-placement="left"
          title="Main folder that you will be working with"
        >
          <FontAwesomeIcon
            icon={icon ? icon : faFolder}
            className={`${color} me-2`}
          />{" "}
          {name}
        </li>
      </OverlayTrigger>
    );
  };

  const redirectToPath = (path) => {
    history.push({ pathname: path });
  };
  return (
    <>
      <Navbar
        variant="dark"
        expand="lg"
        bg="dark"
        className="navbar-transparent navbar-theme-primary sticky-top"
      >
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand
            as={HashLink}
            to="#home"
            className="me-lg-3 d-flex align-items-center"
          >
            <Image src={OJS_LOGO} className="logo-size" />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="ms-2 brand-text d-none d-md-inline">
                Biology Analysis
              </span>
              <span className="ms-2 brand-text d-none d-md-inline">
                And Evaluation
              </span>
            </div>
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            <Navbar.Collapse id="navbar-default-primary">
              <Nav className="navbar-nav-hover align-items-lg-center">
                <Nav.Link as={Link} to={Routes.Presentation.path}>
                  Home
                </Nav.Link>

                <NavDropdown
                  title={
                    <>
                      About The Journal <FontAwesomeIcon icon={faChevronDown} />
                    </>
                  }
                  id="pages-dropdown"
                  show={showDropdown}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <NavDropdown.Item
                    className="nav--sub-link-home"
                    as={HashLink}
                    to={Routes.Presentation.path}
                  >
                    Aim And Scopes
                  </NavDropdown.Item>
                  <NavDropdown.Item as={HashLink} to={Routes.Presentation.path}>
                    Call For Paper
                  </NavDropdown.Item>
                  <NavDropdown.Item as={HashLink} to={Routes.Presentation.path}>
                    Publisher
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={Routes.PrivacyStatement.path}>
                    Privacy Statement
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  as={Link}
                  to={Routes.EditorialBoard.path}
                  className="d-sm-none d-xl-inline"
                >
                  Editorial Board
                </Nav.Link>
                <NavDropdown
                  title={
                    <>
                      Submit Your Manuscript{" "}
                      <FontAwesomeIcon icon={faChevronDown} />
                    </>
                  }
                  id="pages-dropdown"
                  show={showDropdownSubmission}
                  onMouseEnter={handleMouseEnterSubmission}
                  onMouseLeave={handleMouseLeaveSubmission}
                >
                  {!localStorage.getItem("ojs_home_access_token") && (
                    <NavDropdown.Item as={Link} to={Routes.Signin.path}>
                      Online Submission
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item
                    as={Link}
                    to={Routes.PublicationEthics.path}
                  >
                    Publication ethics
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={Routes.GuidelineAuthor.path}>
                    Guideline for author
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={Routes.GuidelineReviewer.path}
                  >
                    Guideline for reviewer
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={Routes.GuidelineAssociateEditor.path}
                  >
                    Guideline for associate editor
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to={Routes.Issues.path}>
                  Issues
                </Nav.Link>
                <Nav.Link as={Link} to={Routes.Presentation.path}>
                  Contact
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            {localStorage.getItem("ojs_home_access_token") ? (
              userStore.userInfo && (
                <div
                  className="media d-flex align-items-center cursor-pointer"
                  onClick={() => redirectToPath(Routes.AllSubmissions.path)}
                >
                  {/* <Image
                    src={`https://www.gravatar.com/avatar/${userStore.userInfo.email}?d=identicon`}
                    className="user-avatar md-avatar rounded-circle"
                  /> */}
                  <div className="media-body ms-2 text-white align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">
                      {userStore.userInfo.first_name}{" "}
                      {userStore.userInfo.last_name}
                    </span>
                  </div>
                </div>
              )
            ) : (
              <>
                <Button
                  variant="outline-white"
                  as={Link}
                  to={Routes.Signin.path}
                  className="ms-3"
                >
                  Sign In
                </Button>
              </>
            )}
          </div>
        </Container>
      </Navbar>
      <section
        className="section-header overflow-hidden pt-5 pt-lg-6 pb-9 pb-lg-4 bg-primary text-white"
        id="home"
      >
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <div className="react-big-icon d-none d-lg-block">
                <span className="fab fa-react"></span>
              </div>
              <h1 className="fw-bolder text-secondary">Publication ethics</h1>
              <p className="text-muted fw-light mb-5 h5">
                Publication ethics Section
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section
        className="section section-md bg-soft mt-5 pt-lg-3"
        id="features"
      >
        <Container>
          <Row className="justify-content-between align-items-center mb-2 mb-lg-4">
            <Col lg={8} className="order-lg-2 mb-2 mb-lg-0">
              <h2>Biology Analysis and Evaluation Publication Ethics</h2>
              <p className="mb-3">
                Biology Analysis and Evaluation provides high-quality scientific
                publications through a peer-review process and obvious ethical
                policies and standards. The ethical behavior of authors, peer
                reviewers, editors, and publishers, ensures honesty, fairness,
                and public trust in scientific works. Through its ethical
                policies, Biology Analysis and Evaluation guarantee the
                integrity of innovative research works and the scientists’
                safety. The adequate publication process leads to public trust
                in scientific knowledge and its reuse by the community.
              </p>
              <h5>1. Ethical Guideline for Authors</h5>
              <p className="mb-4">
                All submitted manuscripts should conform to the Biology Analysis
                and Evaluation editorial and ethical policies provided by{" "}
                <span className="underline-text">
                  {" "}
                  <Card.Link as={Link} to={Routes.GuidelineAuthor.path}>
                    Guide for Authors.
                  </Card.Link>
                </span>
              </p>
              <p className="mb-1">
                To submit manuscripts to Biology Analysis and Evaluation,
                authors must:
              </p>
              <ul>
                <li>
                  Carefully consider all the contributors who are qualified for
                  authorship.
                </li>
                <li> Disclose any possible conflict of interest.</li>
                <li>
                  Be aware that plagiarism, slicing, and simultaneous submission
                  are not permitted.
                </li>
                <li>Honestly present their data and results interpretation.</li>
                <li>
                  Provide permission for translations and previously published
                  content.
                </li>
                <li>
                  Promptly communicate inaccuracies found after publication.
                </li>
                <li>
                  {" "}
                  Be aware of local regulations and academic publishing
                  standards.
                </li>
              </ul>
              <h6>1.1. Authorship of the manuscript</h6>
              <p className="mb-1">
                To qualify an author, three basic criteria must be set.
              </p>
              <ul>
                <li>
                  Substantial contributions to the work conception and design as
                  well as the data acquisition, analysis, and interpretation.
                </li>
                <li>
                  Drafting the work and/or revising it for significant
                  intellectual content.
                </li>
                <li>
                  Approval the final version of the work and agree with its
                  accuracy and integrity.
                </li>
              </ul>
              <p className="mb-4">
                Non-authorship-qualified contributors should be listed in the
                acknowledgment (including the writer’s contribution).
              </p>
              <p className="mb-4">
                Authors are supposed to carefully consider authorship before
                manuscript submissions to the journal. Any change in the authors
                list (addition, removal, or rearrangement) should be approved by
                all authors. The request for any change in the author’s list
                must be sent to the editor as an authorship change request
                signed by all the authors that includes the reason for the
                change. The request for change in the authors list should be
                made during the editorial process and before manuscript
                acceptance. Requests received after the manuscript acceptance
                will result in a delay in the publication. Requests received
                after the manuscript publication require the publication of a
                Correction. The editor reserves the right to request evidence of
                the authorship and change the authorship after acceptance.
              </p>
              <p className="mb-4">
                The corresponding author is the point of contact between the
                editor and the other authors. He should inform and involve them
                in the decisions about the publication.
              </p>
              <p className="mb-4">
                Joint first authors can be mentioned in the manuscript as
                “author X and author Y equally contributed to this paper”. The
                role of the equal authors should also be explained by their
                equal substantial contributions.
              </p>
              <p className="mb-4">
                The corresponding author or the co-authors should inform the
                editor about any deceased author during the editorial process.
                If the corresponding author passes away during the editorial
                process, co-authors should be nominated by the authorship group
                for this role. A note will be included under the author list
                upon the manuscript publication.
              </p>
              <p className="mb-4">
                The use of Artificial Intelligence (AI) and AI-assisted
                technology tools (such as ChatGPT or large language models
                (LLMs)) should be appropriately detailed in the Materials and
                Methods section and Acknowledgments sections.
              </p>
              <p className="mb-4">
                In case of authorship disputes that might occur either during
                processing or after publication, the journal is not in a
                position to adjudicate on appropriate authorship contributions.
                Disputed authorship is not usually grounds for retraction when
                “there is no reason to doubt the validity of the findings”{" "}
                <span className="underline-text">
                  <Card.Link
                    href={"https://publicationethics.org/retraction-guidelines"}
                    target="_blank"
                  >
                    (https://publicationethics.org/retraction-guidelines).
                  </Card.Link>
                </span>
              </p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-between align-items-center mb-2 mb-lg-4">
            <Col lg={8} className="order-lg-2 mb-2 mb-lg-0">
              <h6>1.2. Conflicts of Interests</h6>
              <p className="mb-4">
                The author must disclose, when submitting his manuscript, any
                interest that can influence inappropriately the result
                interpretation.
              </p>
              <p className="mb-4">
                The role of the funding sponsors must be declared.
              </p>
              <p className="mb-4">
                The corresponding author must include in a separate section of
                the manuscript “Conflicts of Interest” placed just before the
                reference list.
              </p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-between align-items-center mb-2 mb-lg-4">
            <Col lg={8} className="order-lg-2 mb-2 mb-lg-0">
              <h6>1.3. Plagiarism</h6>
              <p className="mb-4">
                Biology Analysis and Evaluation does not accept plagiarism and
                considers it as a publication misconduct fact. Plagiarism
                includes literal copying, substantial copying, paraphrasing, and
                text recycling. All the manuscripts submitted to the journal are
                checked for plagiarism. The plagiarism detected during the
                editorial process induces the rejection of the manuscript.
              </p>
              <h6>1.4. Simultaneous submission </h6>
              <p className="mb-4">
                Biology Analysis and Evaluation considers duplicate publication
                of original research manuscripts as unethical. Authors must
                confirm that the submitted paper is original and has never been
                published.
              </p>
              <p className="mb-4">
                Biology Analysis and Evaluation may consider for publication
                high-quality content that has been previously published in a
                different language. Authors must mention in the cover letter
                that their manuscript is a translated version and insert all the
                authors of the original paper on their submitted manuscript. The
                original article must be referenced in the Acknowledgments
                section. Authors must provide all the documents that guarantee
                copyright holding as well as permission from the publisher
                and/or the authors of the original article.
              </p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-between align-items-center mb-2 mb-lg-4">
            <Col lg={8} className="order-lg-2 mb-2 mb-lg-0">
              <h6>1.5. Research fraud</h6>
              <p className="mb-4">
                Research fraud is publishing data that are fabricated or
                falsified. If a referee spots certain instances of fraud, an
                investigation would be conducted to determine if an act of fraud
                was committed.
              </p>
              <span className="bold-text">1.5.1. Manipulation of images </span>
              <p className="mb-4">
                Biology Analysis and Evaluation does not accept any modification
                of the specific feature of the image files (enhancement,
                removal, moving, or introduction) that could lead to the
                misinterpretation of the results. If an image manipulation is
                identified during the editorial process, the manuscript may be
                rejected. The adjustment of contrast, brightness, or color
                balance that does not obscure or eliminate information provided
                by the original image can be accepted.
              </p>
              <span className="bold-text">
                1.5.2. Data access and retention
              </span>
              <p className="mb-4">
                Biology Analysis and Evaluation does not accept any data
                inappropriate selection, modification, enhancement, or
                fabrication. Records of raw data should be accessible to the
                editor when he asks. If data manipulation is identified during
                the editorial process, the manuscript may be rejected.
              </p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-between align-items-center mb-2 mb-lg-4">
            <Col lg={8} className="order-lg-2 mb-2 mb-lg-0">
              <h6>1.6. Salami slicing</h6>
              <p className="mb-4">
                Biology Analysis and Evaluation does not accept broken-up
                studies that have the same hypotheses, population, and methods.
              </p>
              <h6>1.7. Research on human subjects</h6>
              <span className="bold-text">The interventional studies</span>
              <p className="mb-4">
                The research that involves human subjects (material, tissues,
                data…) must be declared as carried out following the latest
                revision of the Helsinki rules declaration (1975){" "}
                <span className="underline-text">
                  {" "}
                  <Card.Link href={"https://www.wma.net"} target="_blank">
                    (https://www.wma.net).
                  </Card.Link>{" "}
                </span>{" "}
                Approval from the local Institutional Review Board (IRB) or
                other similar ethics committee must be obtained before starting
                the research work. This approval must confirm that the study
                fits the international and national ethical guidelines.
              </p>
              <span className="bold-text">The non-interventional studies</span>
              <p className="mb-4">
                For research that involves{" "}
                <span className="bold-text">non-interventional studies</span>{" "}
                such as surveys, questionnaires, etc. Approval from an
                appropriate ethics committee must be obtained before starting
                the research work. Also, a statement explaining the anonymity of
                all participants must be given. Personal private information
                must not be included in the manuscript. Authors of manuscripts
                that contain such data must have signed informed permission for
                publication from the data owners before submission.
              </p>
              <h6>1.8. Research on animals and cell lines</h6>
              <p className="mb-4">
                Authors should ensure that their research procedures comply with
                the ‘Three Rs’ outlines{" "}
                <span className="underline-text">
                  <Card.Link
                    href={"https://www.animalethics.org.au/three-rs"}
                    target="_blank"
                  >
                    https://www.animalethics.org.au/three-rs.
                  </Card.Link>
                </span>{" "}
                Details on housing and husbandry, as well as pain management,
                should conform to the guidelines of the European Animal Research
                Association{" "}
                <span className="underline-text">
                  <Card.Link
                    href={"https://www.eara.eu/animal-research-law"}
                    target="_blank"
                  >
                    https://www.eara.eu/animal-research-law
                  </Card.Link>
                </span>
                , Practice for the Housing and Care of Animals Used in
                Scientific Procedures{" "}
                <span className="underline-text">
                  <Card.Link
                    href={
                      "https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/388535/CoPanimalsWeb.pdf"
                    }
                    target="_blank"
                  >
                    https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/388535/CoPanimalsWeb.pdf
                  </Card.Link>
                </span>
                and American association for laboratory Animal Science{" "}
                <span className="underline-text">
                  <Card.Link
                    href={
                      "https://www.aalas.org/about-aalas/position-papers/scientific-basis-for-regulation-of-animal-care-and-use"
                    }
                    target="_blank"
                  >
                    https://www.aalas.org/about-aalas/position-papers/scientific-basis-for-regulation-of-animal-care-and-use
                  </Card.Link>
                </span>
              </p>
              <p className="mb-4">
                Approval from the national ethics committee, if required, must
                be obtained before starting the research work and sent to the
                editor during the manuscript submission. If the research work
                concerns client-owned animals, the editor must receive signed
                informed permission (from the owners) for publication from the
                data owners before submission.
              </p>
              <p className="mb-4">
                The use of cell lines should be well described in the manuscript
                (type, origin, commercial source, previously published articles,
                etc.). Approval must be given from an appropriate ethics
                committee for the de novo cell lines that have not been
                previously published.
              </p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-between align-items-center mb-2 mb-lg-4">
            <Col lg={8} className="order-lg-2 mb-2 mb-lg-0">
              <h2>Copyright and Intellectual Property </h2>
              <p className="mb-3">
                Once Biology Analysis and Evaluation accepts a manuscript for
                publication, the copyright is kept by the authors under the{" "}
                <span className="underline-text-black">
                  Creative Commons CC BY 4.0
                </span>{" "}
                license. This license allows anyone to freely read and download
                the article. Also, the article may be reused and redistributed
                as long as the original published version is cited. These
                conditions ensure proper credit for authors as it concerns the
                maximum use and exposure of their work.
              </p>
              <h5>2. Ethical Guidelines for Reviewers and Editors</h5>
              <p className="mb-4">
                Editor-in-Chief, Members of the Editorial Board, Guest Editors,
                and Reviewers, which are the participants in the peer-review
                process, are responsible for checking the Biology Analysis and
                Evaluation{" "}
                <span className="underline-text">
                  <Card.Link as={Link} to={Routes.EditorialBoard.path}>
                    Editorial and Peer Review Processes.
                  </Card.Link>
                </span>
              </p>
              <span className="bold-text">A review obligations</span>
              <h6>2.1. Expertise: </h6>
              <p className="mb-1">
                Reviewers are chosen on the base of their expertise.
              </p>
              <h6>2.2. Objectivity: </h6>
              <p className="mb-1">
                <span className="bold-text">
                  A reviewer must accept the invitation for a manuscript review
                  only if he has no bias. Reviewer must declare that he has no
                  conflict of interest to disclose.
                </span>{" "}
                In the event of a conflict of interest, alternative Reviewers
                and/or Editors will be found. If an Editor submits a manuscript
                to the journal, their submission will be handled by other
                Editors who do not have a conflict of interest.
              </p>
              <span className="bold-text">
                Potential Conflicts of Interests
              </span>
              <p className="mb-1">
                Editors and reviewers must declare any associations that
                interfere with the submitted manuscript. The Reviewer or the
                Editor who feels that a conflict of interest will impact the
                decision-making process, should decline to protect the
                objectivity of the peer-review process. These conflicts of
                interest could be:
              </p>
              <span className="bold-text">Personal conflict of interest:</span>
              <p className="mb-1">
                <span className="bold-text">Neither</span> Reviewers nor editors
                <span className="bold-text">should be</span> authorship,
                contributors or collaborators to the submitted manuscript.
              </p>
              <span className="bold-text">Financial conflicts: </span>
              <p className="mb-1">
                It comprises any professional relationships, commercial or
                financial interests, or any competing interests that may
                introduce bias into the edition and the review process. Neither
                Reviewers nor Editors should get personal or professional
                benefit grants from any company that is interested in any
                information given in the submitted manuscript.
              </p>
              <span className="bold-text">Other Conflicts</span>
              <p className="mb-1">
                Reviewers and Editors should assess the manuscript’s value
                according to the{" "}
                <span className="underline-text">
                  <Card.Link as={Link} to={Routes.EditorialBoard.path}>
                    Editorial and Peer Review Processes
                  </Card.Link>
                </span>{" "}
                and do not accept any discrimination based on race, color,
                religion, country of origin, gender, age, physical ability,
                sexual orientation, or socioeconomic status. Reviewers and
                Editors must disclose personal biases.
              </p>
              <h6>2.3. Confidentiality: </h6>
              <p className="mb-1">
                All the manuscripts submitted to Biology Analysis and Evaluation
                are peer-reviewed according to the{" "}
                <span className="bold-text">
                  single-blind peer review process.
                </span>{" "}
                Reviewers should not reveal their identity to the authors in any
                way. Also, all manuscripts sent to the reviewers should be taken
                as confidential documents.
              </p>
              <h6>3. Timeliness: </h6>
              <p className="mb-1">
                Reviewers should complete their reviews and send Comments to the
                author” and the editor within the deadline fixed by the journal.
              </p>
              <p className="mb-4">
                Before and during the peer-review process, the Editors should
                check and report to the reviewer the following:
              </p>
              <ul>
                <li>
                  Permissions for research concerning human subjects, animals,
                  or cell lines.
                </li>
                <li>
                  {" "}
                  Plagiarism, Simultaneous submission, and all the permissions
                  that the authors hold to reuse already-published figures or
                  images for a manuscript translation.{" "}
                </li>
                <li>
                  Any other documents in accordance with Biology Analysis and
                  Evaluation statements and guidelines.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-between align-items-center mb-2 mb-lg-4">
            <Col lg={8} className="order-lg-2 mb-2 mb-lg-0">
              <span className="bold-text">Complaints</span>
              <p className="mb-4">
                Any complaints about a published paper should be addressed first
                to the corresponding author. Readers who attempt resolutions
                should contact the corresponding author via his email mentioned
                on the first page of the article. If a direct resolution is not
                possible, readers can contact the Editorial office. The
                editorial board investigates all complaints, remarks, and update
                demands concerning already published papers. The final decisions
                (Updating Published Papers, etc.) are taken by the
                Editor-in-Chief and the Editorial Board All decisions are in
                favor of joining the principles of publication ethics.
              </p>
              <span className="bold-text">Updating Published Papers</span>
              <p className="mb-4">
                Ethical issues and scientifically significant errors have been
                brought to the Biology Analysis and Evaluation attention can be
                corrected. Any requests to correct metadata errors (e.g., title,
                author name, abstract, etc.) could be completed and an updated
                version of the manuscript will be published.
              </p>
              <span className="bold-text">Cases of eventual corrections</span>
              <p className="mb-4">
                The Requests received to correct errors in the following cases
                could be completed and will include the publication of a
                Correction notice:
              </p>
              <ul>
                <li>
                  Removal or addition an author, an affiliation, an author
                  contribution, a funder.
                </li>
                <li>
                  Removal or addition figures, tables, a reference, an
                  acknowledgement.
                </li>
                <li>
                  Scientific interpretation mistakes (e.g. data interpretation)
                  that do not affect the conclusion.
                </li>
              </ul>
              <p className="mb-4">
                When a request to correct such errors is approved, the
                manuscript will be re-published with the publication of a
                Correction notice. The Correction notice will be published along
                with the updated paper. The Correction serves the purpose of
                notifying all readers that a significant change has occurred to
                the paper, and that the revised version is now available on the
                website.
              </p>
              <span className="bold-text">Concerns, Comments, and Replies</span>
              <p className="mb-4">
                When a reader has concerns about reported results or methods in
                a specific paper published by Biology Analysis and Evaluation,
                he could present his comments as a short letter to the journal’s
                Editorial board. If the reader’s arguments are considered
                reasonable and are approved, the authors will be invited to
                replay. If the reader’s complaints are substantiated, and the
                authors are not able to adequately answer the concerns, a
                correction of the original paper may be published, or the paper
                can be retracted entirely. If authors do not present an answer
                by the deadline provided, or decide not to respond, the Comment
                may be published alongside a note that explains the absence of
                the Reply. Only one round of Comment and Reply will be
                facilitated where that discussion is from the same reader/s.
              </p>
              <p className="mb-4">
                Comments criticizing the work’s authors are not tolerable.
                Comments should not reiterate previously published
                disagreements.
              </p>
              <span className="bold-text">Contact</span>
              <br />
              <span className="bold-text">Publication Ethics team</span>
              <br />
              <span className="bold-text underline-text">
                publication.ethics@baejournal.com
              </span>
            </Col>
          </Row>
        </Container>
      </section>
      <footer className="footer py-6 bg-dark text-white">
        <Container>
          <Row>
            <Col md={4}>
              <Navbar.Brand
                as={HashLink}
                to="#home"
                className="me-lg-3 mb-3 d-flex align-items-center"
              >
                <Image src={OJS_LOGO} />
                <span className="ms-2 brand-text">
                  Biology Analysis and Evaluation
                </span>
              </Navbar.Brand>
              <p>
                Is an international, peer-reviewed, open access journal of
                biological sciences.
              </p>
            </Col>
            <Col xs={6} md={4} className="mb-5 mb-lg-0">
              <span className="h5">Biol. Anal. Eval</span>
              <ul className="links-vertical mt-2">
                <li>
                  <Card.Link as={HashLink} to="#abbreviation">
                    Abbreviation
                  </Card.Link>
                </li>
                <li>
                  <Card.Link as={HashLink} to="#aimAndScope">
                    Aim And Scopes
                  </Card.Link>
                </li>
                <li>
                  <Card.Link as={HashLink} to="#callForPaper">
                    Call For Paper
                  </Card.Link>
                </li>
                <li>
                  <Card.Link as={HashLink} to="#publisher">
                    Publisher
                  </Card.Link>
                </li>
              </ul>
            </Col>
          </Row>
          <hr className="bg-gray my-5" />
          <Row>
            <Col className="mb-md-2">
              <div
                className="d-flex text-center justify-content-center align-items-center"
                role="contentinfo"
              >
                <p className="font-weight-normal font-small mb-0">
                  Copyright © Biology Analysis and Evaluation{" "}
                  <span className="current-year">2023</span>. All rights
                  reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
