import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faFolder,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Image,
  Button,
  Container,
  Tooltip,
  OverlayTrigger,
  Navbar,
  Nav,
  NavDropdown,
} from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { Routes } from "../routes";
import OJS_LOGO from "../assets/img/logo_ojs.png";
import { useGlobalContext } from "../context/useGlobalContext";

export default () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownSubmission, setShowDropdownSubmission] = useState(false);
  const { userStore } = useGlobalContext();
  const history = useHistory();

  const handleMouseEnter = () => {
    setShowDropdownSubmission(false);
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };
  const handleMouseEnterSubmission = () => {
    setShowDropdown(false);
    setShowDropdownSubmission(true);
  };

  const handleMouseLeaveSubmission = () => {
    setShowDropdownSubmission(false);
  };
  const PagePreview = (props) => {
    const { name, image, link } = props;

    return (
      <Col xs={6} className="mb-5">
        <Card.Link
          as={Link}
          to={link}
          className="page-preview page-preview-lg scale-up-hover-2"
        >
          <Image
            src={image}
            className="shadow-lg rounded scale"
            alt="Dashboard page preview"
          />

          <div className="text-center show-on-hover">
            <h6 className="m-0 text-center text-white">
              {name}{" "}
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
            </h6>
          </div>
        </Card.Link>
      </Col>
    );
  };

  const Feature = (props) => {
    const { title, description, icon } = props;

    return (
      <Col xs={12} sm={6} lg={3}>
        <Card className="bg-white shadow-soft text-primary rounded mb-4">
          <div className="px-3 px-lg-4 py-5 text-center">
            <span className="icon icon-lg mb-4">
              <FontAwesomeIcon icon={icon} />
            </span>
            <h5 className="fw-bold text-primary">{title}</h5>
            <p>{description}</p>
          </div>
        </Card>
      </Col>
    );
  };

  const redirectToPath = (path) => {
    history.push({ pathname: path });
  };

  const FolderItem = (props) => {
    const { name, icon, tooltip, iconColor } = props;
    const color = iconColor ? `text-${iconColor}` : "";

    return (
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="left"
        overlay={<Tooltip>{tooltip}</Tooltip>}
      >
        <li
          data-toggle="tooltip"
          data-placement="left"
          title="Main folder that you will be working with"
        >
          <FontAwesomeIcon
            icon={icon ? icon : faFolder}
            className={`${color} me-2`}
          />{" "}
          {name}
        </li>
      </OverlayTrigger>
    );
  };

  return (
    <>
      <Navbar
        variant="dark"
        expand="lg"
        bg="dark"
        className="navbar-transparent navbar-theme-primary sticky-top"
      >
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand
            as={HashLink}
            to="#home"
            className="me-lg-3 d-flex align-items-center"
          >
            <Image src={OJS_LOGO} className="logo-size" />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="ms-2 brand-text d-none d-md-inline">
                Biology Analysis
              </span>
              <span className="ms-2 brand-text d-none d-md-inline">
                And Evaluation
              </span>
            </div>
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            <Navbar.Collapse id="navbar-default-primary">
              <Nav className="navbar-nav-hover align-items-lg-center">
                <Nav.Link as={Link} to={Routes.Presentation.path}>
                  Home
                </Nav.Link>

                <NavDropdown
                  title={
                    <>
                      About The Journal <FontAwesomeIcon icon={faChevronDown} />
                    </>
                  }
                  id="pages-dropdown"
                  show={showDropdown}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <NavDropdown.Item
                    className="nav--sub-link-home"
                    as={HashLink}
                    to={Routes.Presentation.path}
                  >
                    Aim And Scopes
                  </NavDropdown.Item>
                  <NavDropdown.Item as={HashLink} to={Routes.Presentation.path}>
                    Call For Paper
                  </NavDropdown.Item>
                  <NavDropdown.Item as={HashLink} to={Routes.Presentation.path}>
                    Publisher
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={Routes.PrivacyStatement.path}>
                    Privacy Statement
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  as={Link}
                  to={Routes.EditorialBoard.path}
                  className="d-sm-none d-xl-inline"
                >
                  Editorial Board
                </Nav.Link>
                <NavDropdown
                  title={
                    <>
                      Submit Your Manuscript{" "}
                      <FontAwesomeIcon icon={faChevronDown} />
                    </>
                  }
                  id="pages-dropdown"
                  show={showDropdownSubmission}
                  onMouseEnter={handleMouseEnterSubmission}
                  onMouseLeave={handleMouseLeaveSubmission}
                >
                  {!localStorage.getItem("ojs_home_access_token") && (
                    <NavDropdown.Item as={Link} to={Routes.Signin.path}>
                      Online Submission
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item
                    as={Link}
                    to={Routes.PublicationEthics.path}
                  >
                    Publication ethics
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={Routes.GuidelineAuthor.path}>
                    Guideline for author
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={Routes.GuidelineReviewer.path}
                  >
                    Guideline for reviewer
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={Routes.GuidelineAssociateEditor.path}
                  >
                    Guideline for associate editor
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to={Routes.Issues.path}>
                  Issues
                </Nav.Link>
                <Nav.Link as={Link} to={Routes.Presentation.path}>
                  Contact
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            {localStorage.getItem("ojs_home_access_token") ? (
              userStore.userInfo && (
                <div
                  className="media d-flex align-items-center cursor-pointer"
                  onClick={() => redirectToPath(Routes.AllSubmissions.path)}
                >
                  {/* <Image
                    src={`https://www.gravatar.com/avatar/${userStore.userInfo.email}?d=identicon`}
                    className="user-avatar md-avatar rounded-circle"
                  /> */}
                  <div className="media-body ms-2 text-white align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">
                      {userStore.userInfo.first_name}{" "}
                      {userStore.userInfo.last_name}
                    </span>
                  </div>
                </div>
              )
            ) : (
              <>
                <Button
                  variant="outline-white"
                  as={Link}
                  to={Routes.Signin.path}
                  className="ms-3"
                >
                  Sign In
                </Button>
              </>
            )}
          </div>
        </Container>
      </Navbar>
      {/* pt-5 pt-lg-6 pb-9 pb-lg-12 */}
      <section
        className="section-header overflow-hidden pt-5 pt-lg-6 pb-9 pb-lg-4 bg-primary text-white"
        id="home"
      >
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <div className="react-big-icon d-none d-lg-block">
                <span className="fab fa-react"></span>
              </div>
              <h1 className="fw-bolder text-secondary">
                Guideline for reviewer
              </h1>
              <p className="text-muted fw-light mb-5 h5">
                Guideline for reviewer Section
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section
        className="section section-md bg-soft mt-5 pt-lg-3"
        id="features"
      >
        <Container>
          <Row className="justify-content-between align-items-center mb-2 mb-lg-4">
            <Col lg={8} className="order-lg-2 mb-2 mb-lg-0">
              <h2>1. Editorial and Peer Review Processes</h2>
              <p className="mb-3">
                All the manuscripts submitted to Biology Analysis and Evaluation
                are peer-reviewed according to the{" "}
                <span className="bold-text">
                  single-blind peer review process
                </span>{" "}
                in agreement with the following procedure: The manuscript is
                first evaluated by the Editor to determine if it follows the
                journal’s{" "}
                <span className="underline-text">
                  <Card.Link as={Link} to={Routes.PublicationEthics.path}>
                    Publication Ethics.
                  </Card.Link>
                </span>{" "}
                Manuscripts that are not under the journal's ethics policy will
                be rejected before peer review. The editor determines also if
                the manuscript is prepared according to the{" "}
                <span className="underline-text">
                  <Card.Link as={Link} to={Routes.GuidelineAuthor.path}>
                    Guide for Authors
                  </Card.Link>
                </span>{" "}
                and whether it fits the journal’s scope. Manuscripts that are
                not properly prepared will be returned to the authors for
                revision and re-submission. Manuscripts that are not considered
                suitable for the journal are returned to the authors. At this
                stage, any rejection must be verified by the Editor-in-Chief.
              </p>
              <p className="mb-4">
                Manuscripts that pass the first review are assigned to at least
                two reviewers to evaluate the scientific quality.
              </p>
              <p className="mb-4">
                The editor’s decision about the acceptability of the manuscript
                is based on the reviewers’ comments and his recommendations.
              </p>
              <p className="mb-1">
                The reviewer’s evaluation of the manuscript will focus on the
                following criteria:
              </p>
              <ul>
                <li>Originality and academic contribution to the field.</li>
                <li>Concision and clarity.</li>
                <li>
                  Relevance of the used methods to the manuscript objectives.
                </li>
                <li>
                  Coherence of discussion and conclusion with the results.
                </li>
                <li>Adequateness of the cited references.</li>
                <li>Language quality.</li>
                <li>Quality of the figures and the tables. </li>
              </ul>
              <p className="mb-4">
                The editor communicates the decision to the authors, along with
                the reviewers’ reports.
              </p>
              <p className="mb-1">
                The editor’s decision will be one of the following:
              </p>
              <ul>
                <li>
                  Accepted without revisions: The paper is accepted for
                  publication in Biology Analysis and Evaluation.
                </li>
                <li>
                  Accepted with minor revisions: The paper is accepted after
                  minor revisions mentioned in the reviewer’s comments. Authors
                  are given seven days for minor revisions..
                </li>
                <li>
                  Accepted with major revisions: Authors should provide a
                  point-by-point response to the reviewer’s comments or provide
                  evidence when revision is not possible. One or two rounds of
                  major revisions are allowed for a submitted manuscript.
                  Authors should resubmit the revised paper within the deadline
                  fixed by the journal.
                </li>
                <li>
                  Rejected and encourage re-submission: When reviewers recommend
                  further experiments to support the conclusion, authors are
                  encouraged to re-submit the manuscript once they have
                  conclusive experimental results.
                </li>
                <li>
                  Rejected: Manuscripts that do not adhere to Biology Analysis
                  and Evaluation’s standards are rejected.
                </li>
                <li>Language quality.</li>
                <li>Quality of the figures and the tables. </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-between align-items-center mb-2 mb-lg-4">
            <Col lg={8} className="order-lg-2 mb-2 mb-lg-0">
              <h2>2. Comments on the author’s report</h2>
              <p className="mb-3">
                Reviewers provided sufficient feedback and should be suitable to
                review the submission.
              </p>
              <span className="bold-text">2.1. Writing the review:</span>
              <p className="mb-4">
                The main purpose of the Comments on the author’s report is to
                help the Editor make a final decision about the manuscript. This
                report should start with a positive statement highlighting the
                authors’ research efforts and the most important findings. The
                report should also include the reviewer’s overall impression of
                the manuscript and address both the strengths and weaknesses
                aspects. The review’s comment should be written in a
                constructive and respectful, yet critical, manner. That’s to
                provide constructive suggestions to authors on how to improve
                their manuscript and the research (clarity, quality of writing,
                experimental details, overall presentation, references, etc..).
              </p>
              <p className="mb-1">
                The reviewer’s Comments on the author’s report should be
                arranged as follows:
              </p>
              <span className="bold-text">General comments</span>
              <ul>
                <li>
                  Introductory sentences describing the manuscript topic,
                  objectives, and methods.
                </li>
                <li>
                  Few sentences summarizing the reviewer’s general assessment:
                  The strong and the weak points of the manuscript.
                </li>
              </ul>
              <span className="bold-text">The recommendation</span>
              <ul>
                <li>
                  Accept without revision (meets Biology Analysis and Evaluation
                  publication criteria based on the quality of the writing of
                  the research work).
                </li>
                <li>
                  Accepted with major Revisions (should address the following
                  points before re-submission).
                </li>
                <li>
                  Accepted with minor Revisions (should be
                  re-organized/re-formatted before re-submission).
                </li>
                <li>
                  Rejected and encourage re-submission (Should add further
                  experiments).
                </li>
                <li>
                  Reject (does not meet Biology Analysis and Evaluation
                  standards).
                </li>
              </ul>
              <span className="bold-text">Specific comments</span>
              <p className="mb-1">
                Comments to authors should be numbered. Here are some examples
                of specific comments:
              </p>
              <ul>
                <li>1. p.X, line Y, unclear, please rephrase.</li>
                <li>
                  2. p. Z, line W, please support this statement with
                  experimental results.
                </li>
                <li>3. Table 1, please improve the table quality.</li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-between align-items-center mb-2 mb-lg-4">
            <Col lg={8} className="order-lg-2 mb-2 mb-lg-0">
              <h2>Reviewing declaration </h2>
              <p className="mb-0">
                Reviewer declares that he has no conflict of interest to
                disclose.
              </p>
              <p className="mb-0">
                Reviewer accepts the{" "}
                <span className="underline-text">
                  <Card.Link as={Link} to={Routes.PublicationEthics.path}>
                    Publication ethics.
                  </Card.Link>
                </span>
              </p>
              <p className="mb-0">
                Accepting the review indicates respecting the deadline.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <footer className="footer py-6 bg-dark text-white">
        <Container>
          <Row>
            <Col md={4}>
              <Navbar.Brand
                as={HashLink}
                to="#home"
                className="me-lg-3 mb-3 d-flex align-items-center"
              >
                <Image src={OJS_LOGO} />
                <span className="ms-2 brand-text">
                  Biology Analysis and Evaluation
                </span>
              </Navbar.Brand>
              <p>
                Is an international, peer-reviewed, open access journal of
                biological sciences.
              </p>
            </Col>
            <Col xs={6} md={4} className="mb-5 mb-lg-0">
              <span className="h5">Biol. Anal. Eval</span>
              <ul className="links-vertical mt-2">
                <li>
                  <Card.Link as={HashLink} to="#abbreviation">
                    Abbreviation
                  </Card.Link>
                </li>
                <li>
                  <Card.Link as={HashLink} to="#aimAndScope">
                    Aim And Scopes
                  </Card.Link>
                </li>
                <li>
                  <Card.Link as={HashLink} to="#callForPaper">
                    Call For Paper
                  </Card.Link>
                </li>
                <li>
                  <Card.Link as={HashLink} to="#publisher">
                    Publisher
                  </Card.Link>
                </li>
              </ul>
            </Col>
          </Row>
          <hr className="bg-gray my-5" />
          <Row>
            <Col className="mb-md-2">
              <div
                className="d-flex text-center justify-content-center align-items-center"
                role="contentinfo"
              >
                <p className="font-weight-normal font-small mb-0">
                  Copyright © Biology Analysis and Evaluation{" "}
                  <span className="current-year">2023</span>. All rights
                  reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
