import axios from "axios";

export function defaultOptions() {
  const token = localStorage.getItem("ojs_home_access_token");
  const globalSettings = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return globalSettings;
}

export function resetPasswordOptions(token) {
  const globalSettings = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return globalSettings;
}

export function formDataOption() {
  const token = localStorage.getItem("ojs_home_access_token");
  const globalSettings = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  return globalSettings;
}

// Get method
export const get = async (url, options) => {
  try {
    const { data } = await axios.get(url, options);
    return data;
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem("ojs_home_access_token");
    }
    throw new Error(error);
  }
};

export const post = async (url, payload, options) => {
  try {
    const { data } = await axios.post(url, payload, options);
    return data;
  } catch (error) {
    // handle error 
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem("ojs_home_access_token");
    }
    if (error.response) {
      throw new Error(JSON.stringify(error.response.data));
    } else {
      throw new Error(error.message);
    }
  }
};
export const patch = async (url, payload, options) => {
  try {
    const { data } = await axios.patch(url, payload, options);
    return data;
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem("ojs_home_access_token");
    }
    if (error.response) {
      throw new Error(JSON.stringify(error.response.data));
    } else {
      throw new Error(error.message);
    }
  }
};
export const put = async (url, payload, options) => {
  try {
    const { data } = await axios.put(url, payload, options);
    return data;
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem("ojs_home_access_token");
    }
    if (error.response) {
      throw new Error(JSON.stringify(error.response.data));
    } else {
      throw new Error(error.message);
    }
  }
};

// delete method
export const deleteHttp = async (url, options) => {
  try {
    const { data } = await axios.delete(url, options);
    return data;
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem("ojs_home_access_token");
    }
    throw new Error(error);
  }
};
