import React from "react";
import { Col, Row, Card, Form, Button } from "@themesberg/react-bootstrap";
import { observer } from "mobx-react";

import { useGlobalContext } from "../../context/useGlobalContext";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import * as submissionService from "../../services/submission.service";
import { Alert } from "bootstrap";
import { useParams } from "react-router-dom";
import Preloader from "../../components/Preloader";

const UploadFile = observer(() => {
  const { id } = useParams();
  const { stepperStore, submissionStore } = useGlobalContext();
  const [isErrorExist, setIsErrorExist] = useState(false);
  const [isLoding, setIsLoding] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      coverLetter: submissionStore.coverLetter,
      manuscript: submissionStore.maunscript,
    },
  });

  const handleNextPage = async (body) => {
    const formData = new FormData();
    formData.append("title", body.title);
    formData.append("abstract", body.abstract);
    submissionStore.setTitle(body.title);
    submissionStore.setAbstract(body.title);

    let files = [];

    if (submissionStore.coverLetter.type && !submissionStore.maunscript.type) {
      formData.append("coverLetter", submissionStore.coverLetter);
    } else if (
      !submissionStore.coverLetter.type &&
      submissionStore.maunscript.type
    ) {
      formData.append("manuscript", submissionStore.maunscript);
    } else if (
      submissionStore.coverLetter.type &&
      submissionStore.maunscript.type
    ) {
      formData.append("coverLetter", submissionStore.coverLetter);
      formData.append("manuscript", submissionStore.maunscript);
    }

    try {   

      setIsLoding(true);
      if (id) {
        console.log("first");
        const result = await submissionService.editUploadFiles(formData, id);
        if (result) {
          setIsLoding(true);
          setIsErrorExist(false);
          submissionStore.setFileLink(result.fileUrl);
          submissionStore.setFileId(result.fileId);
          stepperStore.getNextPage();
        }
      } else if (!id && submissionStore.fileId !== "") {
        console.log("second");

        const result = await submissionService.editUploadFiles(
          formData,
          submissionStore.fileId
        );
        if (result) {
          setIsLoding(true);
          setIsErrorExist(false);
          submissionStore.setFileLink(result.fileUrl);
          submissionStore.setFileId(result.fileId);
          stepperStore.getNextPage();
        }
      } else {
        console.log("third");

        const result = await submissionService.uploadFiles(formData);
        if (result) {
          setIsLoding(true);
          setIsErrorExist(false);
          submissionStore.setFileLink(result.fileUrl);
          submissionStore.setFileId(result.fileId);
          stepperStore.getNextPage();
        }
      }
    } catch (error) {
      setIsErrorExist(true);
    }
  };
  const handlePreviousPage = () => {
    stepperStore.getPreviousPage();
  };

  const handleConverLetterFileChange = (event) => {
    submissionStore.setCoverLetter(event.target.files[0]);
  };
  const handleMaunscriptFileChange = (event) => {
    submissionStore.setMaunscripterLetter(event.target.files[0]);
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Preloader show={isLoding} />
      {isErrorExist && (
        <Alert
          variant={"danger"}
          style={{
            textAlign: "center",
          }}
        >
          Something want wrong! Please try again!
        </Alert>
      )}
      <Card.Body>
        <h5 className="mb-4">Upload your files</h5>
        <Form>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group id="title">
                <Form.Label>Title*</Form.Label>
                <Controller
                  control={control}
                  name="title"
                  defaultValue={submissionStore.title}
                  rules={{
                    required: {
                      value: true,
                      message: "The title field is mandatory",
                    },
                  }}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Form.Control
                      required
                      type="text"
                      placeholder="Title"
                      {...field}
                    />
                  )}
                />
                {errors && errors?.title && (
                  <Form.Text className="error-text">
                    {errors.title.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group id="abstract">
                <Form.Label>Abstract*</Form.Label>
                <Controller
                  control={control}
                  name="abstract"
                  defaultValue={submissionStore.abstract}
                  rules={{
                    required: {
                      value: true,
                      message: "The abstract field is mandatory",
                    },
                  }}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Form.Control
                      as="textarea"
                      required
                      type="text"
                      placeholder="Abstract"
                      {...field}
                    />
                  )}
                />
                {errors && errors?.abstract && (
                  <Form.Text className="error-text">
                    {errors.abstract.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group id="coverletter">
                <Form.Label>
                  Cover Letter*{" "}
                  {id && (
                    <span style={{ color: "red" }}>
                      {" "}
                     To edit click on Choose File
                    </span>
                  )}
                </Form.Label>
                <Controller
                  control={control}
                  name="coverLetter"
                  rules={{ required: "Please select a cover letter file" }}
                  render={({ field }) => (
                    <div>
                      <div className="input-group custom-file-button">
                        <label
                          className="input-group-text"
                          for="inputGroupFile"
                        >
                          Choose File
                        </label>
                        <input
                          type="file"
                          accept=".doc,.docx"
                          onChange={(e) => {
                            field.onChange(e);
                            handleConverLetterFileChange(e);
                          }}
                          className="form-control"
                          id="inputGroupFile"
                        />
                      </div>
                      {field.value && (
                        <>
                          <p>
                            Selected file:{" "}
                            <span style={{ color: "blue" }}>
                              {submissionStore.coverLetter.name}
                            </span>
                          </p>
                        </>
                      )}
                    </div>
                  )}
                />
                {errors && errors?.coverLetter && (
                  <Form.Text className="error-text">
                    {errors.coverLetter.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={12} className="mb-3">
              <Form.Group id="maunscript">
                <Form.Label>
                  Manuscript*{" "}
                  {id && (
                    <span style={{ color: "red" }}>
                      {" "}
                      To edit click on Choose File
                    </span>
                  )}
                </Form.Label>
                <Controller
                  control={control}
                  name="manuscript"
                  rules={{ required: "Please select a manuscript file" }}
                  render={({ field }) => (
                    <div>
                      {/* <Form.Control
                        required
                        type="file"
                        accept=".doc,.docx"
                        onChange={(e) => {
                          field.onChange(e);
                          handleMaunscriptFileChange(e);
                        }}
                      /> */}
                      <div className="input-group custom-file-button">
                        <label
                          className="input-group-text"
                          for="inputGroupFileMaunscript"
                        >
                          Choose File
                        </label>
                        <input
                          type="file"
                          accept=".doc,.docx"
                          onChange={(e) => {
                            field.onChange(e);
                            handleMaunscriptFileChange(e);
                          }}
                          className="form-control"
                          id="inputGroupFileMaunscript"
                        />
                      </div>
                      {field.value && (
                        <p>
                          Selected file:{" "}
                          <span style={{ color: "blue" }}>
                            {submissionStore.maunscript.name}
                          </span>
                        </p>
                      )}
                    </div>
                  )}
                />
                {errors && errors?.manuscript && (
                  <Form.Text className="error-text">
                    {errors.manuscript.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <div className="block-btns-stepper">
            <div className="mt-3">
              <Button
                variant="primary"
                type="submit"
                onClick={handlePreviousPage}
              >
                Previous
              </Button>
            </div>
            <div className="mt-3">
              <Button variant="primary" onClick={handleSubmit(handleNextPage)}>
                Next
              </Button>
            </div>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
});

export default UploadFile;
