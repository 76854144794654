import {
  defaultOptions,
  deleteHttp,
  formDataOption,
  get,
  patch,
  post,
  put,
} from "../utils/functions/httpMethods";
import * as urlWebservice from "../utils/constants/apis";

export const uploadFiles = async (payload) => {
  try {
    const result = await post(
      urlWebservice.UPLOAD_FILES,
      payload,
      formDataOption()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
export const editUploadFiles = async (payload, id) => {
  try {
    const result = await put(
      `${urlWebservice.EDIT_UPLOAD_FILES}/${id}`,
      payload,
      formDataOption()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
export const addSubmission = async (payload, id) => {
  try {
    const result = await patch(
      `${urlWebservice.ADD_SUBMISSION}/${id}`,
      payload,
      defaultOptions()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSumbission = async () => {
  try {
    const result = await get(
      urlWebservice.GET_ALL_SUBMISSIONS,
      defaultOptions()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
export const approveSumbission = async (id) => {
  try {
    const result = await patch(
      `${urlWebservice.APPROVE_SUBMISSIONS}/${id}`,
      {},
      defaultOptions()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
export const getSumbissionById = async (id) => {
  try {
    const result = await get(
      `${urlWebservice.GET_SUBMISSION_BY_ID}/${id}`,
      defaultOptions()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
export const deleteSumbission = async (id) => {
  try {
    const result = await deleteHttp(
      `${urlWebservice.DELETE_SUBMISSION}/${id}`,
      defaultOptions()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
export const sendEmailSumbission = async (id) => {
  try {
    const result = await post(
      `${urlWebservice.SEND_EMAIL}/${id}`,
      {},
      defaultOptions()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

export const submitReview = async (id, data) => {
  try {
    const result = await post(
      `${urlWebservice.SUBMIT_REVIEW}/${id}`,
      data,
      formDataOption()
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
