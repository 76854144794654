import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faExternalLinkAlt,
  faTimesCircle,
  faCheckCircle,
  faCalendarAlt,
  faCodeBranch,
  faShoppingCart,
  faFolder,
  faMapMarkedAlt,
  faPager,
  faFileCode,
  faDownload,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  faBootstrap,
  faGithub,
  faJs,
  faReact,
  faSass,
} from "@fortawesome/free-brands-svg-icons";
import {
  Col,
  Row,
  Card,
  Image,
  Button,
  Container,
  ListGroup,
  Tooltip,
  OverlayTrigger,
  Form,
  Navbar,
  Nav,
  Badge,
  NavDropdown,
} from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Code from "../components/CodeEditor";
import GitHubButton from "react-github-btn";

import { Routes } from "../routes";
import ThemesbergLogoIcon from "../assets/img/themesberg.svg";
import ThemesbergLogo from "../assets/img/themesberg-logo.svg";
import MockupPresentation from "../assets/img/mockup-presentation.png";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import OJS_LOGO from "../assets/img/logo_ojs.png";
import MapboxImg from "../assets/img/mockup-map-presentation.png";
import CalendarImg from "../assets/img/mockup-calendar-presentation.png";
import ReactMockupImg from "../assets/img/react-mockup.png";
import BS5IllustrationsImg from "../assets/img/illustrations/bs5-illustrations.svg";
import BS5Logo from "../assets/img/technologies/bootstrap-5-logo.svg";
import ReactLogo from "../assets/img/technologies/react-logo.svg";

import pages from "../data/pages";
import features from "../data/features";
import { useGlobalContext } from "../context/useGlobalContext";

export default () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownSubmission, setShowDropdownSubmission] = useState(false);
  const { userStore } = useGlobalContext();
  const history = useHistory();

  const handleMouseEnter = () => {
    setShowDropdownSubmission(false);
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };
  const handleMouseEnterSubmission = () => {
    setShowDropdown(false);
    setShowDropdownSubmission(true);
  };

  const handleMouseLeaveSubmission = () => {
    setShowDropdownSubmission(false);
  };
  const PagePreview = (props) => {
    const { name, image, link } = props;

    return (
      <Col xs={6} className="mb-5">
        <Card.Link
          as={Link}
          to={link}
          className="page-preview page-preview-lg scale-up-hover-2"
        >
          <Image
            src={image}
            className="shadow-lg rounded scale"
            alt="Dashboard page preview"
          />

          <div className="text-center show-on-hover">
            <h6 className="m-0 text-center text-white">
              {name}{" "}
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
            </h6>
          </div>
        </Card.Link>
      </Col>
    );
  };

  const Feature = (props) => {
    const { title, description, icon } = props;

    return (
      <Col xs={12} sm={6} lg={3}>
        <Card className="bg-white shadow-soft text-primary rounded mb-4">
          <div className="px-3 px-lg-4 py-5 text-center">
            <span className="icon icon-lg mb-4">
              <FontAwesomeIcon icon={icon} />
            </span>
            <h5 className="fw-bold text-primary">{title}</h5>
            <p>{description}</p>
          </div>
        </Card>
      </Col>
    );
  };

  const FolderItem = (props) => {
    const { name, icon, tooltip, iconColor } = props;
    const color = iconColor ? `text-${iconColor}` : "";

    return (
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="left"
        overlay={<Tooltip>{tooltip}</Tooltip>}
      >
        <li
          data-toggle="tooltip"
          data-placement="left"
          title="Main folder that you will be working with"
        >
          <FontAwesomeIcon
            icon={icon ? icon : faFolder}
            className={`${color} me-2`}
          />{" "}
          {name}
        </li>
      </OverlayTrigger>
    );
  };
  const redirectToPath = (path) => {
    history.push({ pathname: path });
  };

  return (
    <>
      <Navbar
        variant="dark"
        expand="lg"
        bg="dark"
        className="navbar-transparent navbar-theme-primary sticky-top"
      >
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand
            as={HashLink}
            to="#home"
            className="me-lg-3 d-flex align-items-center"
          >
            <Image src={OJS_LOGO} className="logo-size" />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="ms-2 brand-text d-none d-md-inline">
                Biology Analysis
              </span>
              <span className="ms-2 brand-text d-none d-md-inline">
                And Evaluation
              </span>
            </div>
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            <Navbar.Collapse id="navbar-default-primary">
              <Nav className="navbar-nav-hover align-items-lg-center">
                <Nav.Link as={Link} to={Routes.Presentation.path}>
                  Home
                </Nav.Link>

                <NavDropdown
                  title={
                    <>
                      About The Journal <FontAwesomeIcon icon={faChevronDown} />
                    </>
                  }
                  id="pages-dropdown"
                  show={showDropdown}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <NavDropdown.Item
                    className="nav--sub-link-home"
                    as={HashLink}
                    to={Routes.Presentation.path}
                  >
                    Aim And Scopes
                  </NavDropdown.Item>
                  <NavDropdown.Item as={HashLink} to={Routes.Presentation.path}>
                    Call For Paper
                  </NavDropdown.Item>
                  <NavDropdown.Item as={HashLink} to={Routes.Presentation.path}>
                    Publisher
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={Routes.PrivacyStatement.path}>
                    Privacy Statement
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  as={Link}
                  to={Routes.EditorialBoard.path}
                  className="d-sm-none d-xl-inline"
                >
                  Editorial Board
                </Nav.Link>
                <NavDropdown
                  title={
                    <>
                      Submit Your Manuscript{" "}
                      <FontAwesomeIcon icon={faChevronDown} />
                    </>
                  }
                  id="pages-dropdown"
                  show={showDropdownSubmission}
                  onMouseEnter={handleMouseEnterSubmission}
                  onMouseLeave={handleMouseLeaveSubmission}
                >
                  {!localStorage.getItem("ojs_home_access_token") && (
                    <NavDropdown.Item as={Link} to={Routes.Signin.path}>
                      Online Submission
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item
                    as={Link}
                    to={Routes.PublicationEthics.path}
                  >
                    Publication ethics
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={Routes.GuidelineAuthor.path}>
                    Guideline for author
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={Routes.GuidelineReviewer.path}
                  >
                    Guideline for reviewer
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={Routes.GuidelineAssociateEditor.path}
                  >
                    Guideline for associate editor
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to={Routes.Issues.path}>
                  Issues
                </Nav.Link>
                <Nav.Link as={Link} to={Routes.Presentation.path}>
                  Contact
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            {localStorage.getItem("ojs_home_access_token") ? (
              userStore.userInfo && (
                <div
                  className="media d-flex align-items-center cursor-pointer"
                  onClick={() => redirectToPath(Routes.AllSubmissions.path)}
                >
                  {/* <Image
                    src={`https://www.gravatar.com/avatar/${userStore.userInfo.email}?d=identicon`}
                    className="user-avatar md-avatar rounded-circle"
                  /> */}
                  <div className="media-body ms-2 text-white align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">
                      {userStore.userInfo.first_name}{" "}
                      {userStore.userInfo.last_name}
                    </span>
                  </div>
                </div>
              )
            ) : (
              <>
                <Button
                  variant="outline-white"
                  as={Link}
                  to={Routes.Signin.path}
                  className="ms-3"
                >
                  Sign In
                </Button>
              </>
            )}
          </div>
        </Container>
      </Navbar>
      <section
        className="section-header overflow-hidden pt-5 pt-lg-6 pb-9 pb-lg-4 bg-primary text-white"
        id="home"
      >
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <div className="react-big-icon d-none d-lg-block">
                <span className="fab fa-react"></span>
              </div>
              <h1 className="fw-bolder text-secondary">
                Guideline for associate editor
              </h1>
              <p className="text-muted fw-light mb-5 h5">
                Guideline for associate editor Section
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <footer className="footer py-6 bg-dark text-white">
        <Container>
          <Row>
            <Col md={4}>
              <Navbar.Brand
                as={HashLink}
                to="#home"
                className="me-lg-3 mb-3 d-flex align-items-center"
              >
                <Image src={OJS_LOGO} />
                <span className="ms-2 brand-text">
                  Biology Analysis and Evaluation
                </span>
              </Navbar.Brand>
              <p>
                Is an international, peer-reviewed, open access journal of
                biological sciences.
              </p>
            </Col>
            <Col xs={6} md={4} className="mb-5 mb-lg-0">
              <span className="h5">Biol. Anal. Eval</span>
              <ul className="links-vertical mt-2">
                <li>
                  <Card.Link as={HashLink} to="#abbreviation">
                    Abbreviation
                  </Card.Link>
                </li>
                <li>
                  <Card.Link as={HashLink} to="#aimAndScope">
                    Aim And Scopes
                  </Card.Link>
                </li>
                <li>
                  <Card.Link as={HashLink} to="#callForPaper">
                    Call For Paper
                  </Card.Link>
                </li>
                <li>
                  <Card.Link as={HashLink} to="#publisher">
                    Publisher
                  </Card.Link>
                </li>
              </ul>
            </Col>
          </Row>
          <hr className="bg-gray my-5" />
          <Row>
            <Col className="mb-md-2">
              <div
                className="d-flex text-center justify-content-center align-items-center"
                role="contentinfo"
              >
                <p className="font-weight-normal font-small mb-0">
                  Copyright © Biology Analysis and Evaluation{" "}
                  <span className="current-year">2023</span>. All rights
                  reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
