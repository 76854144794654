import { makeAutoObservable } from "mobx";

class StepperStore {
  activeStep = 0;

  constructor() {
    makeAutoObservable(this);
  }
  // next page
  setActiveStep(value) {
    this.activeStep = value;
  }
  // next page
  getNextPage() {
    this.activeStep = this.activeStep + 1;
  }

  // previous page
  getPreviousPage() {
    this.activeStep = this.activeStep - 1;
  }
}

export default new StepperStore();
