export const Routes = {
  // pages
  Presentation: { path: "/" },
  AimAndScop: { path: "/aim-and-scop" },
  PrivacyStatement: { path: "/privacy-statement" },
  Publisher: { path: "/publisher" },
  EditorialBoard: { path: "/editorial-board" },
  OnlineSubmission: { path: "/online-submission" },
  PublicationEthics: { path: "/publication-ethics" },
  GuidelineAuthor: { path: "/guideline-author" },
  GuidelineReviewer: { path: "/guideline-reviewer" },
  GuidelineAssociateEditor: { path: "/guideline-associate-editor" },
  Issues: { path: "/issues" },
  Submission: { path: "/submission" },
  EditSubmission: { path: "/edit-submission/:id" },
  AllSubmissions: { path: "/all-submissions" },
  AddReview: { path: "/add-review/:id" },
  Settings: { path: "/settings" },
  Upgrade: { path: "/upgrade" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Billing: { path: "/examples/billing" },
  Invoice: { path: "/examples/invoice" },
  Signin: { path: "/sign-in" },
  SigninReviewer: { path: "/sign-in-reviewer" },
  Signup: { path: "/sign-up" },
  SignupReviewer: { path: "/r-signup" },
  ForgotPassword: { path: "/forgot-password" },
  ResetPassword: { path: "/auth/reset-password" },
  VerifyAccount: { path: "/verify-account" },
  Lock: { path: "/examples/lock" },
  NotFound: { path: "/examples/404" },
  ServerError: { path: "/examples/500" },
  DeclinedPage: { path: "/declined-page/declined" },

  // docs
  DocsOverview: { path: "/documentation/overview" },
  DocsDownload: { path: "/documentation/download" },
  DocsQuickStart: { path: "/documentation/quick-start" },
  DocsLicense: { path: "/documentation/license" },
  DocsFolderStructure: { path: "/documentation/folder-structure" },
  DocsBuild: { path: "/documentation/build-tools" },
  DocsChangelog: { path: "/documentation/changelog" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },

  //issues
  IssueDetails: { path: "/issues/:id" },


};
