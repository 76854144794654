import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCog,
  faDownload,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  Card,
  Table,
  FormCheck,
  Alert,
} from "@themesberg/react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Preloader from "../../components/Preloader";
import { Link, useHistory, useParams } from "react-router-dom";
import { Routes } from "../../routes";
import { ReivewesListEnum } from "../../utils/constants";
import * as submissionService from "../../services/submission.service";
import { useGlobalContext } from "../../context/useGlobalContext";
import { BASE_URL } from "../../utils/constants/apis";

export default () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isAproveToReceiveFile, setIsAproveToReceiveFile] = useState(false);
  const history = useHistory();
  const { submissionStore } = useGlobalContext();

  const { id } = useParams();
  const [selectedOptionEvalution, setSelectedOptionEvalution] = useState({
    originalityAndContribution: ReivewesListEnum.Poor_Originality,
    concisionAndClarity: ReivewesListEnum.Poor_Concision,
    relevanceOfMethods: ReivewesListEnum.Poor_Relevance,
    coherenceOfDiscussion: ReivewesListEnum.Poor_Coherence,
    adequatenessOfReferences: ReivewesListEnum.Poor_Adequateness,
    languageQuality: ReivewesListEnum.Poor_Language,
    qualityOfFiguresAndTables: ReivewesListEnum.Poor_Quality,
  });
  const [scoreOptionEvalution, setScoreOptionEvalution] = useState({
    originalityAndContribution: 0,
    concisionAndClarity: 0,
    relevanceOfMethods: 0,
    coherenceOfDiscussion: 0,
    adequatenessOfReferences: 0,
    languageQuality: 0,
    qualityOfFiguresAndTables: 0,
  });
  const [selectedOptionRecommandation, setSelectedOptionRecommandation] =
    useState("Accepted without revision");
  const [authorComment, setAuthorComment] = useState();
  const [editorComment, setEditorComment] = useState();
  const [file, setFile] = useState();
  console.log(file);

  const getSubmissionById = async () => {
    try {
      const result = await submissionStore.getSumbissionFileById(id);
      setFile(
        `${BASE_URL}/${result.file.filePath}/${
          result.file.manuscript.substring(
            0,
            result.file.manuscript.lastIndexOf(".")
          ) + ".pdf"
        }`
      );
    } catch (error) {}
  };

  useEffect(() => {
    getSubmissionById();
  }, []);

  const totale = Object.values(scoreOptionEvalution).reduce(
    (acc, currentValue) => acc + currentValue,
    0
  );

  const handleOptionRecommandationChange = (value) => {
    setSelectedOptionRecommandation(value);
  };

  const handleAuthorCommentFileChange = (event) => {
    setAuthorComment(event.target.files[0]);
  };
  const handleEditorCommentFileChange = (event) => {
    setEditorComment(event.target.files[0]);
  };

  const handleSumitReview = async (data) => {
    const formData = new FormData();
    formData.append("recommendation", selectedOptionRecommandation);
    formData.append("revisionReceived", Boolean(isAproveToReceiveFile));
    formData.append("commentsToAuthors", data.commentsToAuthors);
    formData.append("commentsToEditor", data.commentsToEditor);
    formData.append(
      "isDeclaredNoConflictOfInterest",
      data.declaredNoConflictOfInterest
    );
    formData.append(
      "isAcceptedPublicationEthics",
      data.acceptedPublicationEthics
    );
    formData.append(
      "originalityAndContribution",
      JSON.stringify({
        rating: selectedOptionEvalution.originalityAndContribution,
        total: selectedOptionEvalution.originalityAndContribution,
      })
    );
    formData.append(
      "concisionAndClarity",
      JSON.stringify({
        rating: selectedOptionEvalution.concisionAndClarity,
        total: selectedOptionEvalution.concisionAndClarity,
      })
    );
    formData.append(
      "relevanceOfMethods",
      JSON.stringify({
        rating: selectedOptionEvalution.relevanceOfMethods,
        total: selectedOptionEvalution.relevanceOfMethods,
      })
    );
    formData.append(
      "coherenceOfDiscussion",
      JSON.stringify({
        rating: selectedOptionEvalution.coherenceOfDiscussion,
        total: selectedOptionEvalution.coherenceOfDiscussion,
      })
    );
    formData.append(
      "adequatenessOfReferences",
      JSON.stringify({
        rating: selectedOptionEvalution.adequatenessOfReferences,
        total: selectedOptionEvalution.adequatenessOfReferences,
      })
    );
    formData.append(
      "languageQuality",
      JSON.stringify({
        rating: selectedOptionEvalution.languageQuality,
        total: selectedOptionEvalution.languageQuality,
      })
    );
    formData.append(
      "qualityOfFiguresAndTables",
      JSON.stringify({
        rating: selectedOptionEvalution.qualityOfFiguresAndTables,
        total: selectedOptionEvalution.qualityOfFiguresAndTables,
      })
    );

    formData.append("score", totale);

    // files if exist
    formData.append("authorComment", authorComment);
    formData.append("editorComment", editorComment);

    console.log("formData =====>", formData);
    try {
      const result = await submissionService.submitReview(id, formData);
      console.log("result  ===>", result);
      if (result) {
        history.push({ pathname: Routes.AllSubmissions.path });
        setIsError(false);
      }
    } catch (error) {
      if (error.message) {
        let parsedErrorMessage = error.message;
        try {
          const innerError = JSON.parse(parsedErrorMessage);
          parsedErrorMessage = innerError.message;
        } catch (e) {}
        console.log("parsedErrorMessage ===>", parsedErrorMessage);
        setErrorMessage(parsedErrorMessage);
        setIsError(true);
      } else {
        setErrorMessage("An error occurred");
        setIsError(true);
      }

      // if (error) {
      //   const errorMessage = error;
      //   // console.log("errorMessage ====>", errorMessage);

      //   if (Array.isArray(errorMessage.message)) {
      //     setErrorMessage(errorMessage.message[0]);
      //   } else {
      //     setErrorMessage(errorMessage.message);
      //   }
      //   setIsError(true);
      // }
    }
  };

  return (
    <>
      {/* <Preloader show={isLoding} /> */}

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0"></div>
      </div>
      {isError && (
        <Alert
          variant={"danger"}
          style={{
            textAlign: "center",
          }}
        >
          {errorMessage}!
        </Alert>
      )}

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <h4>What is you overall evalution of the paper</h4>
          <Form.Group>
            <h6 className="mb-4 mt-4" style={{ display: "flex" }}>
              <p>Click here to read the pdf</p>
              <a href={file} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon
                  style={{ marginLeft: 10, cursor: "pointer" }}
                  icon={faDownload}
                />
              </a>
            </h6>
            <h6 className="mb-4 mt-4" style={{ display: "flex" }}>
              <p>Would you like to receive the revision of this manuscript?</p>
              <p className="underline-text p-marign-left">
                <Form.Check
                  type="radio"
                  id="defaultCheck-1"
                  className="me-2"
                  checked={isAproveToReceiveFile === true}
                  onChange={() => setIsAproveToReceiveFile(true)}
                  label="Yes"
                />
              </p>
              <p className="p-marign-left"> /</p>
              <p className="underline-text p-marign-left">
                {" "}
                <Form.Check
                  type="radio"
                  id="defaultCheck-2"
                  checked={isAproveToReceiveFile === false}
                  onChange={() => setIsAproveToReceiveFile(false)}
                  label="No"
                  className="mb-0"
                />
              </p>
            </h6>
            <h6 className="error-text">
              This document is confidential and is proprietary to Biology
              Analysis and Evaluation journal and its authors. Do not copy or
              disclose without permission.
            </h6>
            <Table bordered>
              <tbody>
                <tr>
                  <td></td>
                  <td>Poor</td>
                  <td>Fair</td>
                  <td>Good</td>
                  <td>Excellent</td>
                </tr>
                <tr>
                  <td style={{ position: "relative" }}>
                    Originality and academic contribution to the field
                    {/* <span
                      style={{
                        position: "absolute",
                        right: "4%",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Score: {scoreOptionEvalution.originalityAndContribution}
                    </span> */}
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck5"
                      checked={
                        selectedOptionEvalution.originalityAndContribution ==
                        ReivewesListEnum.Poor_Originality
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          originalityAndContribution:
                            ReivewesListEnum.Poor_Originality,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          originalityAndContribution: 20 * 0.6,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck6"
                      checked={
                        selectedOptionEvalution.originalityAndContribution ==
                        ReivewesListEnum.Fair_Originality
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          originalityAndContribution:
                            ReivewesListEnum.Fair_Originality,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          originalityAndContribution: 20 * 0.7,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck7"
                      checked={
                        selectedOptionEvalution.originalityAndContribution ==
                        ReivewesListEnum.Good_Originality
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          originalityAndContribution:
                            ReivewesListEnum.Good_Originality,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          originalityAndContribution: 20 * 0.9,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck7"
                      checked={
                        selectedOptionEvalution.originalityAndContribution ==
                        ReivewesListEnum.Excellent_Originality
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          originalityAndContribution:
                            ReivewesListEnum.Excellent_Originality,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          originalityAndContribution: 20,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ position: "relative" }}>
                    Concision and clarity.
                    {/* <span
                      style={{
                        position: "absolute",
                        right: "4%",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Score: {scoreOptionEvalution.concisionAndClarity}
                    </span> */}
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck5"
                      checked={
                        selectedOptionEvalution.concisionAndClarity ==
                        ReivewesListEnum.Poor_Concision
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          concisionAndClarity: ReivewesListEnum.Poor_Concision,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          concisionAndClarity: 10 * 0.6,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck6"
                      checked={
                        selectedOptionEvalution.concisionAndClarity ==
                        ReivewesListEnum.Fair_Concision
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          concisionAndClarity: ReivewesListEnum.Fair_Concision,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          concisionAndClarity: 10 * 0.7,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck7"
                      checked={
                        selectedOptionEvalution.concisionAndClarity ==
                        ReivewesListEnum.Good_Concision
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          concisionAndClarity: ReivewesListEnum.Good_Concision,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          concisionAndClarity: 10 * 0.9,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck7"
                      checked={
                        selectedOptionEvalution.concisionAndClarity ==
                        ReivewesListEnum.Excellent_Concision
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          concisionAndClarity:
                            ReivewesListEnum.Excellent_Concision,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          concisionAndClarity: 10,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ position: "relative" }}>
                    Relevance of the used methods to the manuscript objectives.
                    {/* <span
                      style={{
                        position: "absolute",
                        right: "4%",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Score: {scoreOptionEvalution.relevanceOfMethods}
                    </span> */}
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck5"
                      checked={
                        selectedOptionEvalution.relevanceOfMethods ==
                        ReivewesListEnum.Poor_Relevance
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          relevanceOfMethods: ReivewesListEnum.Poor_Relevance,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          relevanceOfMethods: 20 * 0.6,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck6"
                      checked={
                        selectedOptionEvalution.relevanceOfMethods ==
                        ReivewesListEnum.Fair_Relevance
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          relevanceOfMethods: ReivewesListEnum.Fair_Relevance,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          relevanceOfMethods: 20 * 0.7,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck7"
                      checked={
                        selectedOptionEvalution.relevanceOfMethods ==
                        ReivewesListEnum.Good_Relevance
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          relevanceOfMethods: ReivewesListEnum.Good_Relevance,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          relevanceOfMethods: 20 * 0.9,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck7"
                      checked={
                        selectedOptionEvalution.relevanceOfMethods ==
                        ReivewesListEnum.Excellent_Relevance
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          relevanceOfMethods:
                            ReivewesListEnum.Excellent_Relevance,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          relevanceOfMethods: 20,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ position: "relative" }}>
                    Coherence of discussion and conclusion with the results. (20
                    Pts)
                    {/* <span
                      style={{
                        position: "absolute",
                        right: "4%",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Score: {scoreOptionEvalution.coherenceOfDiscussion}
                    </span> */}
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck5"
                      checked={
                        selectedOptionEvalution.coherenceOfDiscussion ==
                        ReivewesListEnum.Poor_Coherence
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          coherenceOfDiscussion:
                            ReivewesListEnum.Poor_Coherence,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          coherenceOfDiscussion: 20 * 0.6,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck6"
                      checked={
                        selectedOptionEvalution.coherenceOfDiscussion ==
                        ReivewesListEnum.Fair_Coherence
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          coherenceOfDiscussion:
                            ReivewesListEnum.Fair_Coherence,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          coherenceOfDiscussion: 20 * 0.7,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck7"
                      checked={
                        selectedOptionEvalution.coherenceOfDiscussion ==
                        ReivewesListEnum.Good_Coherence
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          coherenceOfDiscussion:
                            ReivewesListEnum.Good_Coherence,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          coherenceOfDiscussion: 20 * 0.9,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck7"
                      checked={
                        selectedOptionEvalution.coherenceOfDiscussion ==
                        ReivewesListEnum.Excellent_Coherence
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          coherenceOfDiscussion:
                            ReivewesListEnum.Excellent_Coherence,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          coherenceOfDiscussion: 20,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ position: "relative" }}>
                    Adequateness of the cited references.{" "}
                    {/* <span
                      style={{
                        position: "absolute",
                        right: "4%",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Score: {scoreOptionEvalution.adequatenessOfReferences}
                    </span> */}
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck5"
                      checked={
                        selectedOptionEvalution.adequatenessOfReferences ==
                        ReivewesListEnum.Poor_Adequateness
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          adequatenessOfReferences:
                            ReivewesListEnum.Poor_Adequateness,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          adequatenessOfReferences: 10 * 0.6,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck6"
                      checked={
                        selectedOptionEvalution.adequatenessOfReferences ==
                        ReivewesListEnum.Fair_Adequateness
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          adequatenessOfReferences:
                            ReivewesListEnum.Fair_Adequateness,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          adequatenessOfReferences: 10 * 0.7,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck7"
                      checked={
                        selectedOptionEvalution.adequatenessOfReferences ==
                        ReivewesListEnum.Good_Adequateness
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          adequatenessOfReferences:
                            ReivewesListEnum.Good_Adequateness,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          adequatenessOfReferences: 10 * 0.9,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck7"
                      checked={
                        selectedOptionEvalution.adequatenessOfReferences ==
                        ReivewesListEnum.Excellent_Adequateness
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          adequatenessOfReferences:
                            ReivewesListEnum.Excellent_Adequateness,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          adequatenessOfReferences: 10,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ position: "relative" }}>
                    Language quality.{" "}
                    {/* <span
                      style={{
                        position: "absolute",
                        right: "4%",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Score: {scoreOptionEvalution.languageQuality}
                    </span> */}
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck5"
                      checked={
                        selectedOptionEvalution.languageQuality ==
                        ReivewesListEnum.Poor_Language
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          languageQuality: ReivewesListEnum.Poor_Language,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          languageQuality: 15 * 0.6,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck6"
                      checked={
                        selectedOptionEvalution.languageQuality ==
                        ReivewesListEnum.Fair_Language
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          languageQuality: ReivewesListEnum.Fair_Language,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          languageQuality: 15 * 0.7,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      checked={
                        selectedOptionEvalution.languageQuality ==
                        ReivewesListEnum.Good_Language
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          languageQuality: ReivewesListEnum.Good_Language,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          languageQuality: 15 * 0.9,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck7"
                      checked={
                        selectedOptionEvalution.languageQuality ==
                        ReivewesListEnum.Excellent_Language
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          languageQuality: ReivewesListEnum.Excellent_Language,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          languageQuality: 15,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ position: "relative" }}>
                    Quality of the figures and the tables.{" "}
                    {/* <span
                      style={{
                        position: "absolute",
                        right: "4%",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Score: {scoreOptionEvalution.qualityOfFiguresAndTables}
                    </span> */}
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck5"
                      checked={
                        selectedOptionEvalution.qualityOfFiguresAndTables ==
                        ReivewesListEnum.Poor_Quality
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          qualityOfFiguresAndTables:
                            ReivewesListEnum.Poor_Quality,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          qualityOfFiguresAndTables: 5 * 0.6,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck6"
                      checked={
                        selectedOptionEvalution.qualityOfFiguresAndTables ==
                        ReivewesListEnum.Fair_Quality
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          qualityOfFiguresAndTables:
                            ReivewesListEnum.Fair_Quality,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          qualityOfFiguresAndTables: 5 * 0.7,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck7"
                      checked={
                        selectedOptionEvalution.qualityOfFiguresAndTables ==
                        ReivewesListEnum.Good_Quality
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          qualityOfFiguresAndTables:
                            ReivewesListEnum.Good_Quality,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          qualityOfFiguresAndTables: 5 * 0.9,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="radio"
                      id="defaultCheck7"
                      checked={
                        selectedOptionEvalution.qualityOfFiguresAndTables ==
                        ReivewesListEnum.Excellent_Quality
                      }
                      onChange={() => {
                        setSelectedOptionEvalution({
                          ...selectedOptionEvalution,
                          qualityOfFiguresAndTables:
                            ReivewesListEnum.Excellent_Quality,
                        });
                        setScoreOptionEvalution({
                          ...scoreOptionEvalution,
                          qualityOfFiguresAndTables: 5,
                        });
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            <h6 style={{ textAlign: "right" }}>Totale Score : {totale}</h6>
          </Form.Group>
        </Row>
        <Row className="justify-content-between align-items-center">
          <h5 className="mb-4">Comments</h5>
          <Form>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group id="abstract">
                  <Form.Label>Comments to the Authors*</Form.Label>
                  <Controller
                    control={control}
                    name="commentsToAuthors"
                    // defaultValue={submissionStore.abstract}
                    rules={{
                      required: {
                        value: true,
                        message:
                          "The Comments to the Authors field is mandatory",
                      },
                    }}
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <Form.Control
                        as="textarea"
                        required
                        type="text"
                        placeholder="Comments to the Authors"
                        {...field}
                      />
                    )}
                  />
                  {errors && errors?.commentsToAuthors && (
                    <Form.Text className="error-text">
                      {errors.commentsToAuthors.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group id="coverletter">
                  <Controller
                    control={control}
                    name="authorComment"
                    // rules={{ required: "Please select a cover letter file" }}
                    render={({ field }) => (
                      <div>
                        <div className="input-group custom-file-button">
                          <label
                            className="input-group-text"
                            for="inputGroupFile"
                          >
                            Choose File
                          </label>
                          <input
                            type="file"
                            accept=".doc,.docx"
                            onChange={(e) => {
                              field.onChange(e);
                              handleAuthorCommentFileChange(e);
                            }}
                            className="form-control"
                            id="inputGroupFile"
                          />
                        </div>
                        {/* {field.value && (
                        <>
                          <p>
                            Selected file:{" "}
                            <span style={{ color: "blue" }}>
                              {submissionStore.coverLetter.name}
                            </span>
                          </p>
                        </>
                      )} */}
                      </div>
                    )}
                  />
                  {/* {errors && errors?.coverLetter && (
                  <Form.Text className="error-text">
                    {errors.coverLetter.message}
                  </Form.Text>
                )} */}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group id="abstract">
                  <Form.Label>Comments to the Editor</Form.Label>
                  <Controller
                    control={control}
                    name="commentsToEditor"
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <Form.Control
                        as="textarea"
                        required
                        type="text"
                        placeholder="Comments to the Editor"
                        {...field}
                      />
                    )}
                  />
                  {errors && errors?.commentsToEditor && (
                    <Form.Text className="error-text">
                      {errors.commentsToEditor.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md={12} className="mb-3">
                <Form.Group id="maunscript">
                  <Controller
                    control={control}
                    name="editorComment"
                    // rules={{ required: "Please select a manuscript file" }}
                    render={({ field }) => (
                      <div>
                        {/* <Form.Control
                        required
                        type="file"
                        accept=".doc,.docx"
                        onChange={(e) => {
                          field.onChange(e);
                          handleMaunscriptFileChange(e);
                        }}
                      /> */}
                        <div className="input-group custom-file-button">
                          <label
                            className="input-group-text"
                            for="inputGroupFileMaunscript"
                          >
                            Choose File
                          </label>
                          <input
                            type="file"
                            accept=".doc,.docx"
                            onChange={(e) => {
                              field.onChange(e);
                              handleEditorCommentFileChange(e);
                            }}
                            className="form-control"
                            id="inputGroupFileMaunscript"
                          />
                        </div>
                        {field.value && (
                          <p>
                            Selected file:{" "}
                            <span style={{ color: "blue" }}>
                              {/* {submissionStore.maunscript.name} */}
                            </span>
                          </p>
                        )}
                      </div>
                    )}
                  />
                  {/* {errors && errors?.manuscript && (
                  <Form.Text className="error-text">
                    {errors.manuscript.message}
                  </Form.Text>
                )} */}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Row>
        <Row className="justify-content-between align-items-center">
          <h4>Recommendation</h4>
          <Form.Group>
            <div>
              <Form.Check
                type="radio"
                id="defaultCheck-1"
                className="me-2"
                checked={
                  selectedOptionRecommandation == "Accepted without revision"
                }
                onChange={() =>
                  handleOptionRecommandationChange("Accepted without revision")
                }
                label="Accepted without revision"
              />
              <Form.Check
                type="radio"
                id="defaultCheck-2"
                checked={
                  selectedOptionRecommandation == "Accepted with minor revision"
                }
                onChange={() =>
                  handleOptionRecommandationChange(
                    "Accepted with minor revision"
                  )
                }
                label="Accepted with minor revision"
                className="mb-0"
              />
              <Form.Check
                type="radio"
                id="defaultCheck-3"
                checked={
                  selectedOptionRecommandation == "Accepted with major revision"
                }
                onChange={() =>
                  handleOptionRecommandationChange(
                    "Accepted with major revision"
                  )
                }
                label="Accepted with major revision"
                className="mb-0"
              />
              <Form.Check
                type="radio"
                id="defaultCheck-4"
                checked={
                  selectedOptionRecommandation ==
                  "Rejected and encourage re-submission"
                }
                onChange={() =>
                  handleOptionRecommandationChange(
                    "Rejected and encourage re-submission"
                  )
                }
                label="Rejected and encourage re-submission"
                className="mb-0"
              />
              <Form.Check
                type="radio"
                id="defaultCheck-5"
                checked={selectedOptionRecommandation == "Rejected"}
                onChange={() => handleOptionRecommandationChange("Rejected")}
                label="Rejected"
                className="mb-0"
              />
            </div>
          </Form.Group>

          <FormCheck type="checkbox" className="mt-3 mb-1">
            <Controller
              control={control}
              name="declaredNoConflictOfInterest"
              defaultValue={false}
              rules={{
                required: "You must agree to this statement",
              }}
              render={({ field }) => (
                <FormCheck.Input
                  type="checkbox"
                  id="defaultCheck5"
                  className="me-2"
                  {...field}
                />
              )}
            />
            <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">
              I declare that I have no conflict of interset to disclose*
            </FormCheck.Label>
          </FormCheck>
          {errors && errors?.declaredNoConflictOfInterest && (
            <Form.Text className="error-text">
              {errors.declaredNoConflictOfInterest.message}
            </Form.Text>
          )}
          <FormCheck type="checkbox" className="mt-1 mb-1">
            <Controller
              control={control}
              name="acceptedPublicationEthics"
              defaultValue={false}
              rules={{
                required: "You must agree to this statement",
              }}
              render={({ field }) => (
                <FormCheck.Input
                  type="checkbox"
                  id="defaultCheck5"
                  className="me-2"
                  {...field}
                />
              )}
            />
            <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">
              I accept the{" "}
              <Card.Link
                as={Link}
                target="_blank"
                to={Routes.PublicationEthics.path}
                className="underline-text"
              >
                Publication ethics*
              </Card.Link>
            </FormCheck.Label>
          </FormCheck>
          {errors && errors?.acceptedPublicationEthics && (
            <Form.Text className="error-text">
              {errors.acceptedPublicationEthics.message}
            </Form.Text>
          )}
        </Row>
        <div className="block-btns-stepper">
          <div className="mt-3">
            {/* <Button variant="primary">Previous</Button> */}
          </div>
          <div className="mt-3">
            <Button onClick={handleSubmit(handleSumitReview)}>Submit</Button>
          </div>
        </div>
      </div>
    </>
  );
};
